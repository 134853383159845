import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { VendorManagmentModel } from './VendorManagment.model';
import { VendorLocationModel } from './VendorLocation.model';
import { SettingBaseModel } from './SettingBase.model';
import { ServiceItemPricingModel } from './ServiceItemPricing.model';
import { AuthStore } from '@uplink-shared/security';
import { RootDataStore } from '@uplink-shared/layout';

@modelProtection
export class ServiceItemPricingApproval extends CoreModel implements ISelectOption {
  id: number = 0;
  vendor: VendorManagmentModel = new VendorManagmentModel();
  vendorLocation: VendorLocationModel = new VendorLocationModel();
  serviceItem: SettingBaseModel = new SettingBaseModel();
  oldValue: ServiceItemPricingModel = new ServiceItemPricingModel();
  newValue: ServiceItemPricingModel = new ServiceItemPricingModel();
  comment: string = '';
  approvalStatus: SettingBaseModel = new SettingBaseModel();
  serviceItemPricingId: number = 0;
  userId: string = '';

  constructor(data?: Partial<ServiceItemPricingApproval>) {
    super(data);
    Object.assign(this, data);
  }

  public serialize(data: ServiceItemPricingModel,oldData?: ServiceItemPricingModel): ServiceItemPricingApproval {
    return {
      userId: this.userId,
      id: data.approvalId ? data.approvalId : this.id || 0,
      vendorId: AuthStore.vendorProfile.id,
      vendorLocationId: RootDataStore.locationData.locationId,
      serviceItemId: this.serviceItem.id,
      oldValue:
        data.id !== 0 && data.approvalId !== 0
          ? oldData.serializeApprovalData(oldData)
          : data.id !== 0
            ? data.serializeApprovalData(data)
            : null,
      newValue: data.serializeApprovalData(data),
      serviceItemPricingId: data.id ? data.id : this.serviceItemPricingId || null,
    };
  }

  static deserialize(apiData: ServiceItemPricingApproval): ServiceItemPricingApproval {
    if (!apiData) {
      return new ServiceItemPricingApproval();
    }
    const data: Partial<ServiceItemPricingApproval> = {
      ...apiData,
      vendor: VendorManagmentModel.deserialize(apiData.vendor),
      vendorLocation: VendorLocationModel.deserialize(apiData.vendorLocation),
      serviceItem: SettingBaseModel.deserialize(apiData.serviceItem),
      oldValue: apiData.oldValue,
      newValue: apiData.newValue,
      comment: apiData.comment,
      approvalStatus: SettingBaseModel.deserialize(apiData.approvalStatus),
      serviceItemPricingId: apiData.serviceItemPricingId,
    };
    return new ServiceItemPricingApproval(data);
  }

  static deserializeList(apiDataList: ServiceItemPricingApproval[]): ServiceItemPricingApproval[] {
    return apiDataList ? apiDataList.map(apiData => ServiceItemPricingApproval.deserialize(apiData)) : [];
  }

  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
