import { VendorManagementStore } from './VendorManagement.store';
import { SettingsStore } from './Settings.store';
import { VendorLocationStore } from './VendorLocation.store';
import { VendorDocumentStore } from './VendorDocument.store';
import { ContactMasterStore } from './ContactMaster.store';
import { ServiceItemPricingStore } from './ServiceItemPricing.store';
import { ApprovalSettingsStore } from './ApprovalSettings.store';
import { ApprovalDataStore } from './ApprovalData.store';
import { VendorUserStore } from './VendorUser.store';
import { PassengerLogisticsStore } from './PassengerLogistics.store';
import { UserManagementStore } from './UserManagement.store';
import { SlideOneAndTwoStore } from './VendorLocationOnBoarding/SlideOneAndTwoStore.store';
import { SlideThreeStore } from './VendorLocationOnBoarding/SlideThreeStore.store';
import { VendorLocationHoursStore } from './VendorLocationHours.store';
import { BulletinStore } from './Bulletin.store';

class VendorRootStore {
  public vendorManagementStore: VendorManagementStore = new VendorManagementStore();
  public settingsStore: SettingsStore = new SettingsStore();
  public vendorLocationStore: VendorLocationStore = new VendorLocationStore();
  public vendorDocumentStore: VendorDocumentStore = new VendorDocumentStore();
  public contactMasterStore: ContactMasterStore = new ContactMasterStore();
  public serviceItemPricingStore: ServiceItemPricingStore = new ServiceItemPricingStore();
  public approvalSettingsStore: ApprovalSettingsStore = new ApprovalSettingsStore();
  public approvalDataStore: ApprovalDataStore = new ApprovalDataStore();
  public vendorUserStore: VendorUserStore = new VendorUserStore();
  public passengerLogisticsStore: PassengerLogisticsStore = new PassengerLogisticsStore();
  public userManagementStore: UserManagementStore = new UserManagementStore();
  public slideOneAndTwoStore: SlideOneAndTwoStore = new SlideOneAndTwoStore();
  public slideThreeStore: SlideThreeStore = new SlideThreeStore();
  public vendorLocationHoursStore: VendorLocationHoursStore = new VendorLocationHoursStore();
  public bulletinStore: BulletinStore = new BulletinStore();
}

const rootStore = new VendorRootStore();

export default rootStore;
