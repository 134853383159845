import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { VendorLocationStore, VendorManagementStore, VendorUserStore } from '../../Stores';
import { inject, observer } from 'mobx-react';
import { CircularProgress, withStyles } from '@material-ui/core';
import { styles } from './VendorLocations.styles';
import { ISearchHeaderRef, SearchHeaderV2 } from '@uplink-shared/form-controls';
import {
  CustomHeader,
  VendorLocationModel,
  VendorModuleSecurity,
  VENDOR_LOCATION_COMPARISON_FILTERS,
  VENDOR_LOCATION_DATA_FILTER,
  CustomButton,
  IAPIVMSVendorLocationComparison,
  SidebarMenus,
  InfiniteScroll,
  CustomList,
  COLLECTION_NAMES,
  VendorUserModel,
} from '../Shared';
import { agGridUtilities, useAgGrid, useGridState } from '@uplink-shared/custom-ag-grid';
import { IAPIGridRequest, IAPIPageResponse, IClasses, SearchStore, UIStore, ViewPermission } from '@wings-shared/core';
import { gridFilters } from './Fields';
import { AuthStore } from '@uplink-shared/security';
import { finalize, takeUntil } from 'rxjs/operators';
import { useUnsubscribe } from '@wings-shared/hooks';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { useNavigate } from 'react-router';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { MixPanelTrackingEvents } from '@uplink/shared';
import { AnalyticsStore } from '@uplink-shared/analytics';
import { ModeStore } from '@wings-shared/mode-store';

type Props = {
  vendorManagementStore: VendorManagementStore;
  vendorLocationStore: VendorLocationStore;
  vendorUserStore: VendorUserStore;
  classes: IClasses;
};

const VendorLocations: FC<Props> = ({ vendorLocationStore, vendorUserStore, vendorManagementStore, classes }) => {
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const navigate = useNavigate();
  const gridState = useGridState();
  const agGrid = useAgGrid<VENDOR_LOCATION_DATA_FILTER, VendorLocationModel>(gridFilters, gridState);
  const unsubscribe = useUnsubscribe();
  const [ isDataLoaded, setIsDataLoaded ] = useState(false);

  useEffect(() => {
    RootDataStore.setLocation(
      RootDataStore.locationData.locationId ? true : false,
      RootDataStore.locationData.locationId,
      RootDataStore.locationData.locationName,
      RootDataStore.locationData.locationCode,
      RootDataStore.locationData.airportReferenceId
    );
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_LOCATION_LIST);
    SidebarStore.setNavLinks(SidebarMenus(), 'vendor');
    vendorLocationStore.hasDataLoaded = true;
  }, []);

  useEffect(() => {
    if (isDataLoaded) {
      if (!gridState.data || gridState.data.length === 0) {
        RootDataStore.removeLocation();
        SidebarStore.setNavLinks(SidebarMenus(), 'vendor');
      }
    }
  }, [ gridState.data ]);

  const rightContent = (): ReactNode => {
    if(ModeStore.isDevModeEnabled){
      return (
        <div className={classes.buttonContainer}>
          <ViewPermission hasPermission={VendorModuleSecurity.isEditable}>
            <CustomButton
              variant="contained"
              startIcon={<AddRoundedIcon />}
              to="/vendor/new-vendor-information"
              title="Add Location"
              onClick={() => {
                RootDataStore.setLocation(true, 0, '', '', 0);
              }}
              disabled={!VendorModuleSecurity.isEditable}
            />
          </ViewPermission>
        </div>
      );
    }
  };

  const searchCollection = (): IAPIGridRequest | null => {
    const propertyValue = getSearchValue();
    if (propertyValue === '') {
      vendorLocationStore.hasDataLoaded = true;
      return null;
    }
    const filters = [
      {
        propertyName: 'Name',
        propertyValue: propertyValue,
        filterType: 'string',
      },
      {
        propertyName: 'AirportReference.AirportName',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
      {
        propertyName: 'AirportReference.ICAOCode',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
      {
        propertyName: 'AirportReference.IATACode',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
      {
        propertyName: 'AirportReference.FAACode',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
      {
        propertyName: 'AirportReference.RegionalCode',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
      {
        propertyName: 'AirportReference.DisplayCode',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
      {
        propertyName: 'AirportReference.UWACode',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
    ];
    return {
      searchCollection: JSON.stringify(filters),
    };
  };

  const getSearchValue = (): string => {
    const searchHeader = searchHeaderRef.current?.getFilters();
    if (!searchHeader) {
      return null;
    }
    return searchHeader.searchValue || '';
  };

  const setSearchData = (): void => {
    const searchHeaderFilter = searchHeaderRef.current.getFilters();
    SearchStore.searchData.set(location.pathname, {
      searchValue: searchHeaderFilter?.searchValue,
      selectInputsValues: searchHeaderFilter?.selectInputsValues,
      chipValue: searchHeaderFilter.chipValue,
      pagination: gridState.pagination,
    });
  };

  const loadInitialData = (pageRequest?: IAPIGridRequest) => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      ...searchCollection(),
      ...agGrid.filtersApi.gridSortFilters(),
      ...pageRequest,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Vendor.Id',
          propertyValue: AuthStore?.vendorProfile?.id,
        },
      ]),
    };
    vendorLocationStore
      .getVMSComparison(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: IAPIPageResponse<IAPIVMSVendorLocationComparison>) => {
        const results = VendorLocationModel.deserializeList(response.results);
        setIsDataLoaded(true);
        if (results.length === 0) {
          vendorLocationStore.hasDataLoaded = false;
          return;
        }
        if (response.pageNumber === 1) {
          gridState.setGridData([]);
        }
        gridState.setGridData([ ...gridState.data, ...results ]);
      });
  };

  const loadUser = () => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'OktaUserId',
          propertyValue: AuthStore?.user.uid,
        },
        {
          propertyName: 'Vendor.Id',
          propertyValue: AuthStore?.vendorProfile.id,
          operator: 'and',
        },
      ]),
    };
    vendorUserStore
      .getVendorUser(COLLECTION_NAMES.VENDOR_USER, request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: VendorUserModel[]) => {
        const locationList = VendorUserModel.deserialize(response.results[0]).vendorUserLocation.map(
          item => item.vendorLocation
        );
        setIsDataLoaded(true);
        gridState.setGridData(locationList);
        vendorLocationStore.hasDataLoaded = false;
      });
  };

  const colDefNew = [
    {
      headerName: 'Code',
      field: [
        'airportReference.displayCode',
        'airportReference.icaoCode',
        'airportReference.uwaCode',
        'airportReference.faaCode',
        'airportReference.iataCode',
        'airportReference.regionalCode',
        'vendorLocationCityReference.stateReference.code',
      ],
    },
    {
      headerName: 'Name',
      field: 'name',
    },
    {
      headerName: 'Airport Reference',
      field: [ 'airportReference.airportName', 'vendorLocationCityReference.cityReference.name' ],
    },
    {
      headerName: 'Airport/City Code',
      field: [
        'airportReference.displayCode',
        'airportReference.icaoCode',
        'airportReference.uwaCode',
        'airportReference.faaCode',
        'airportReference.iataCode',
        'airportReference.regionalCode',
        'vendorLocationCityReference.countryReference.name',
      ],
    },
    {
      headerName: 'Airport Reference Name',
      field: 'airportReference.airportName',
    },
    {
      field: 'actionRenderer',
      headerName: '\u00A0\u00A0\u00A0\u00A0Edit\u00A0\u00A0\u00A0\u00A0',
    },
  ];

  const loadVendorLocationData = locationId => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVendorLocationById(locationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorLocationModel) => {
        RootDataStore.setLocation(
          true,
          response.id,
          response.name,
          response.code,
          response.airportReference?.id,
          '',
          response.airportDataManagement,
          response.countryDataManagement,
          response.permitDataManagement
        );
      });
  };

  const onEdit = (item, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchData();
    RootDataStore.setLocation(true, item.id, item.name, item.code, item.airportReference?.id);
    navigate('general-information');
  };

  const handleLocationSelect = (locationId, locationName, locationCode, airportReferenceId) => {
    RootDataStore.locationData.isLocationSet && RootDataStore.locationData.locationId == locationId
      ? RootDataStore.setLocation(false, 0, '', '', 0)
      : RootDataStore.setLocation(true, locationId, locationName, locationCode, airportReferenceId);
    loadVendorLocationData(locationId);
    SidebarStore.setNavLinks(SidebarMenus(), 'vendor');
  };

  const userLocationLoades = (pageRequest?: IAPIGridRequest) => {
    if (!VendorModuleSecurity.isEditable) {
      loadUser();
    } else {
      loadInitialData(pageRequest);
    }
  };

  return (
    <>
      <CustomHeader title={`${AuthStore.vendorProfile?.name} Location List`} />
      <SearchHeaderV2
        placeHolder="Start typing to search"
        ref={searchHeaderRef}
        selectInputs={[
          agGridUtilities.createSelectOption(
            VENDOR_LOCATION_COMPARISON_FILTERS,
            VENDOR_LOCATION_COMPARISON_FILTERS.VENDOR_NAME,
            'defaultOption'
          ),
        ]}
        isUsingSelectInputs={false}
        onResetFilterClick={() => {
          setIsDataLoaded(false);
          gridState.setGridData([]);
          vendorLocationStore.hasDataLoaded = true;
          userLocationLoades({ pageNumber: 1 });
        }}
        rightContent={rightContent}
        disableControls={!VendorModuleSecurity.isEditable}
        onFilterChange={isInitEvent => {
          setIsDataLoaded(false);
          gridState.setGridData([]);
          vendorLocationStore.hasDataLoaded = true;
          userLocationLoades({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 });
        }}
      />
      <InfiniteScroll
        pageStart={0}
        loadMore={page => {
          const searchData = SearchStore.searchData.get(location.pathname);
          if (searchData) {
            searchHeaderRef.current?.setupDefaultFilters(searchData);
            SearchStore.clearSearchData(location.pathname);
          }
          setTimeout(() => {
            userLocationLoades({ pageNumber: page });
          }, 200);
        }}
        hasMore={vendorLocationStore.hasDataLoaded && !UIStore.pageLoading}
        loader={
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        }
        useWindow={false}
        resetPagination={!vendorLocationStore.hasDataLoaded}
      >
        <CustomList
          classes={classes}
          colDef={colDefNew}
          rowData={gridState.data}
          isHeaderVisible={false}
          showEditButton={true}
          onEdit={(item, e) => onEdit(item, e)}
          isContact={false}
          isLocation={true}
          showDeleteButton={false}
          isLocationSelected={item => handleLocationSelect(item.id, item.name, item.code, item.airportReference?.id)}
          selectedItemId={RootDataStore.locationData.locationId}
          isLoading={UIStore.pageLoading}
        />
      </InfiniteScroll>
    </>
  );
};

export default inject(
  'vendorLocationStore',
  'vendorManagementStore',
  'vendorUserStore'
)(withStyles(styles)(observer(VendorLocations)));
