import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { AuthStore } from '@uplink-shared/security';
import { VendorManagmentModel } from '../VendorManagment.model';
import { VendorLocationModel } from '../VendorLocation.model';

@modelProtection
export class VendorOnBoardSlideNineModel extends CoreModel implements ISelectOption {
  id: number = 0;
  vendor: VendorManagmentModel = new VendorManagmentModel();
  vendorId: number = 0;
  tempLocationId: string = '';
  groundServiceProviderAppliedVendorLocation: VendorLocationModel = new VendorLocationModel();
  groundServiceProviderAppliedVendorLocationId: number = 0;
  legalBusinessName: string = '';
  managerName: string = '';
  assitManagerName: string = '';
  primaryPhoneNo: string = '';
  secondaryPhoneNo: string = '';
  fax: string = '';
  email: string = '';
  secondaryEmail: string = '';

  constructor(data?: Partial<VendorOnBoardSlideNineModel>) {
    super(data);
    Object.assign(this, data);
  }

  public serialize(): VendorOnBoardSlideNineModel {
    return {
      id: this.id || 0,
      userId: this.userId,
      vendorId: AuthStore.vendorProfile.id,
      tempLocationId: this.tempLocationId ? this.tempLocationId : null,
      groundServiceProviderAppliedVendorLocationId:
        this.groundServiceProviderAppliedVendorLocation?.id === 99999999
          ? null
          : this.groundServiceProviderAppliedVendorLocation?.vendorLocationId || null,
      legalBusinessName: this.legalBusinessName,
      assitManagerName: this.assitManagerName || null,
      managerName: this.managerName || null,
      primaryPhoneNo: this.primaryPhoneNo,
      secondaryPhoneNo: this.secondaryPhoneNo || null,
      fax: this.fax || null,
      email: this.email,
      secondaryEmail: this.secondaryEmail || null,
    };
  }

  static deserialize(apiData: VendorOnBoardSlideNineModel): VendorOnBoardSlideNineModel {
    if (!apiData) {
      return new VendorOnBoardSlideNineModel();
    }
    const data: Partial<VendorOnBoardSlideNineModel> = {
      ...apiData,
      vendor: VendorManagmentModel.deserialize(apiData.vendor),
      groundServiceProviderAppliedVendorLocation:
        apiData.groundServiceProviderAppliedVendorLocation != null
          ? VendorLocationModel.deserialize({
            ...apiData.groundServiceProviderAppliedVendorLocation,
            id: apiData.groundServiceProviderAppliedVendorLocation?.vendorLocationId,
          })
          : null,
    };
    return new VendorOnBoardSlideNineModel(data);
  }

  static deserializeList(apiDataList: VendorOnBoardSlideNineModel[]): VendorOnBoardSlideNineModel[] {
    return apiDataList ? apiDataList.map(apiData => VendorOnBoardSlideNineModel.deserialize(apiData)) : [];
  }

  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
