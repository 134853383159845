import React, { FC, useEffect, useRef, useState, ReactNode } from 'react';
import { IAPIGridRequest, IAPIPageResponse, IClasses, SearchStore, UIStore, ViewPermission } from '@wings-shared/core';
import { BaseStore, SettingsStore, VendorManagementStore } from '../../Stores';
import { useUnsubscribe } from '@wings-shared/hooks';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { SearchHeaderV2, ISearchHeaderRef } from '@uplink-shared/form-controls';
import { finalize, takeUntil } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { SidebarStore } from '@uplink-shared/layout';
import { forkJoin } from 'rxjs';
import {
  SETTING_ID,
  SettingBaseModel,
  VENDOR_ADDRESS_DATA_FILTER,
  VendorManagmentModel,
  VendorModuleSecurity,
  VendorAddressModel,
  COLLECTION_NAMES,
  CustomButton,
  CustomList,
  SidebarMenus,
  CustomDialog,
  CustomTooltip,
} from '../Shared';

import { addressGridFilters } from './Fields';
import { useAgGrid, useGridState } from '@uplink-shared/custom-ag-grid';
import { IAPIResponseVendorAddress } from '../Shared/Interfaces/Response/API-Response-VendorAddress';
import CustomHeader from '../Shared/Components/CustomHeader/CustomHeader';
import { AuthStore } from '@uplink-shared/security';
import { useNavigate } from 'react-router';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { MixPanelTrackingEvents } from '@uplink/shared';
import { AnalyticsStore } from '@uplink-shared/analytics';

interface Props {
  vendorManagementStore: VendorManagementStore;
  settingsStore: SettingsStore;
  vendorId: number;
  classes: IClasses;
}

const VendorAddress: FC<Props> = ({ vendorManagementStore, vendorId, settingsStore, classes }) => {
  const unsubscribe = useUnsubscribe();
  const gridState = useGridState();
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const agGrid = useAgGrid<VENDOR_ADDRESS_DATA_FILTER, VendorAddressModel>(addressGridFilters, gridState);
  const [ selectedVendor, setSelectedVendor ] = useState(new VendorManagmentModel());
  const navigate = useNavigate();

  useEffect(() => {
    SidebarStore.setNavLinks(SidebarMenus(), 'vendor');
    loadVendorData();
    const searchData = SearchStore.searchData.get(location.pathname);
    if (searchData) {
      searchHeaderRef.current?.setupDefaultFilters(searchData);
      SearchStore.clearSearchData(location.pathname);
    }
    setTimeout(() => {
      loadInitialData();
    }, 200);
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_ADDRESS);
  }, [ vendorId ]);

  const rightContent = (): ReactNode => {
    return (
      <ViewPermission hasPermission={VendorModuleSecurity.isEditable}>
        <CustomButton
          variant="contained"
          startIcon={<AddRoundedIcon />}
          to="/vendor/addresses/upsert"
          title="Add Address"
        />
      </ViewPermission>
    );
  };

  const getSearchValue = (): string => {
    const searchHeader = searchHeaderRef.current?.getFilters();
    if (!searchHeader) {
      return null;
    }
    return searchHeader.searchValue || '';
  };

  const setSearchData = (): void => {
    const searchHeaderFilter = searchHeaderRef.current.getFilters();
    SearchStore.searchData.set(location.pathname, {
      searchValue: searchHeaderFilter?.searchValue,
      selectInputsValues: searchHeaderFilter?.selectInputsValues,
      chipValue: searchHeaderFilter.chipValue,
      pagination: gridState.pagination,
    });
  };

  const loadVendorData = () => {
    UIStore.setPageLoader(true);
    vendorManagementStore
      ?.getVendorById(AuthStore?.vendorProfile?.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorManagmentModel) => {
        setSelectedVendor(response);
      });
  };

  const deleteAddress = (item: VendorAddressModel): void => {
    // const model: VendorAddressModel = agGrid._getTableItem(rowIndex);
    UIStore.setPageLoader(true);
    gridState.setIsProcessing(true);
    vendorManagementStore
      .removVendorAddress(item.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          gridState.setIsProcessing(false);
          agGrid.filtersApi.resetColumnFilters();
        })
      )
      .subscribe({
        next: () => {
          agGrid._removeTableItems([ item ]);
          loadInitialData();
        },
        error: error => {
          // agGrid._startEditingCell(rowIndex, 'addressType');
          BaseStore.showAlert(error.message, item.id);
        },
      });
  };

  const loadInitialData = (pageRequest?: IAPIGridRequest) => {
    UIStore.setPageLoader(true);
    gridState.setIsProcessing(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      ...agGrid.filtersApi.gridSortFilters(),
      ...pageRequest,
    };
    const addressRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Id',
          propertyValue: AuthStore?.vendorProfile?.id,
        },
      ]),
      ...agGrid.filtersApi.gridSortFilters(),
      ...pageRequest,
    };
    forkJoin([
      //vendorLocationStore.getVMSLocationAddresses(addressRequest, COLLECTION_NAMES.VENDOR_ADDRESS),
      vendorManagementStore?.getVMSAddressComparison(addressRequest),
      settingsStore?.getSettings(SETTING_ID.SETTING_ADDRESS_TYPE,request),
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          gridState.setIsProcessing(false);
        })
      )
      .subscribe((response: [IAPIPageResponse<IAPIResponseVendorAddress>, SettingBaseModel]) => {
        const results = VendorAddressModel.deserializeList(response[0].results);
        // if (results.length === 0) {
        //   vendorManagementStore.hasDataLoaded = false;
        //   return;
        // }
        gridState.setGridData(filterResults(results));
      });
  };

  const filterResults = (resultList: VendorAddressModel[]): VendorAddressModel[] => {
    const propertyValue = getSearchValue();
    if (propertyValue) {
      const results = resultList.filter(res => {
        return (
          matchValues(res.addressType.name, propertyValue) ||
          matchValues(res.addressLine1, propertyValue) ||
          matchValues(res.addressLine2, propertyValue) ||
          matchValues(res.cityReference?.commonName, propertyValue) ||
          matchValues(res.countryReference?.name, propertyValue) ||
          matchValues(res.stateReference?.name, propertyValue) ||
          matchValues(res.zipCode, propertyValue)
        );
      });
      return results;
    } else {
      return resultList;
    }
  };

  const matchValues = (source: string, target: string): boolean => {
    return source ? source.toLowerCase().includes(target.toLowerCase()) : false;
  };

  const onEdit = item => {
    setSearchData();
    navigate(`upsert/${item?.id}`);
  };

  const colDefNew = [
    {
      headerName: 'Address Type',
      field: 'addressType',
    },
    {
      headerName: 'Address Line 1',
      field: 'addressLine1',
    },
    {
      headerName: 'Address Line2 ',
      field: 'addressLine2',
    },
    {
      headerName: 'Country Name',
      field: 'countryReference.name',
    },
    {
      headerName: 'State Name',
      field: 'stateReference.name',
    },
    {
      headerName: 'City Name',
      field: 'cityReference.commonName',
    },
    {
      headerName: 'Zip Code',
      field: 'zipCode',
    },
    {
      field: 'actionRenderer',
      headerName: '\u00A0\u00A0\u00A0\u00A0Edit\u00A0\u00A0\u00A0\u00A0\u00A0Delete',
    },
  ];

  const getConfirmation = (item: VendorAddressModel): void => {
    ModalStore.open(
      <CustomDialog
        title="Remove this Address"
        message={'Are you sure you want to remove this Address?'}
        yesButton="Remove this Address"
        noButton="Cancel"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => deleteAddress(item)}
      />
    );
  };

  return (
    <>
      <CustomHeader title={<CustomTooltip title={`${selectedVendor.name} Addresses`} />} />
      <SearchHeaderV2
        placeHolder="Start typing to search"
        ref={searchHeaderRef}
        selectInputs={[]}
        onResetFilterClick={() => {
          gridState.setGridData([]);
          loadInitialData({ pageNumber: 1 });
        }}
        rightContent={rightContent}
        onFilterChange={isInitEvent => {
          gridState.setGridData([]);
          loadInitialData({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 });
        }}
        isLoading={UIStore.pageLoading}
      />
      <CustomList
        classes={classes}
        colDef={colDefNew}
        rowData={gridState.data}
        isHeaderVisible={false}
        showEditButton={VendorModuleSecurity.isEditable}
        onEdit={item => onEdit(item)}
        onDelete={item => getConfirmation(item)}
        isContact={false}
        isLocation={false}
        showDeleteButton={VendorModuleSecurity.isEditable}
        isVendorAddress={true}
        isLoading={UIStore.pageLoading}
      />
    </>
  );
};
export default inject('vendorManagementStore', 'settingsStore')(observer(VendorAddress));
