import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  columns: {
    display: 'flex',
    justifyContent: 'space-between',
    textWrap: 'nowrap',
    border: '1px solid #D3D3D3',
    padding: '20px',
    margin: '10px 0px 0px 5px',
    '& .MuiButton-label': {
      textTransform: 'none',
    },
    '& .MuiGrid-grid-xs-4': {
      flexBasis: '0',
    },
    '& .MuiGrid-spacing-xs-3 > .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    '& .MuiOutlinedInput-root .Mui-disabled': {
      backgroundColor: '#7C7C7C',
      color: '#AFAFAF',
    },
    '& .MuiFormControl-marginDense': {
      backgroundColor: '#7C7C7C',
      borderRadius: '5px',
      color: '#AFAFAF',
    },
    '& .MuiOutlinedInput-root.Mui-disabled svg': {
      color: '#AFAFAF',
    },
  },
  innerColumns: {
    display: 'flex',
    justifyContent: 'space-between',
    textWrap: 'nowrap',
  },
  defaultButton: {
    width: '98%',
    '& .MuiButton-root:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'rgba(25, 118, 210, 0.05)',
      color: '#63A4FF',
      cursor: 'pointer',
    },
  },
  timeRangeButton: {
    width: '100%',
    border: '1px solid #1976D2',
    '&:disabled': {
      border: '1px solid #AFAFAF !important',
    },
  },
  inputDropdown: {
    width: '100%',
    color: '#AFAFAF',
    backgroundColor: '#7C7C7C !important',
    borderRadius: '5px',
  },
}));
