import { CoreModel, modelProtection } from '@wings-shared/core';
import { VendorLocationModel } from './VendorLocation.model';
import { Airports } from './Airports.model';
import { SettingBaseModel } from './SettingBase.model';
import { ParkingSeasonalityOptionModel } from './ParkingSeasonalityOptionModel.model';
import { RootDataStore } from '@uplink-shared/layout';

@modelProtection
export class ParkingSeasonalityModel extends CoreModel {
  id: number = 0;
  overnightParkingDifficulty: SettingBaseModel = new SettingBaseModel();
  overnightParkingDifficultyId: number = 0;
  overnightParkingDifficultyName: string = '';
  isMaximumStayAllowed: boolean = false;
  maximumStayDuration: number = 0;
  status: SettingBaseModel = new SettingBaseModel();
  vendorLocation: VendorLocationModel = new VendorLocationModel();
  airportReference: Airports = new Airports();
  appliedParkingSeasonDifficulty: ParkingSeasonalityOptionModel[] = [];
  appliedParkingAlternates: ParkingSeasonalityOptionModel[] = [];

  constructor(data?: Partial<ParkingSeasonalityModel>) {
    super(data);
    Object.assign(this, data);
  }

  public serialize() {
    return {
      userId: this.userId || '',
      id: this.id || 0,
      overnightParkingDifficultyId: this.overnightParkingDifficulty.id || 0,
      overnightParkingDifficultyName: this.overnightParkingDifficulty.name || '',
      isMaximumStayAllowed: this.isMaximumStayAllowed || false,
      maximumStayDuration: this.maximumStayDuration || 0,
      statusId: this.status.id || null,
      vendorLocationId: RootDataStore.locationData.locationId,
      airportReferenceId: RootDataStore.locationData.airportReferenceId,
      appliedParkingSeasonDifficulty: this.appliedParkingSeasonDifficulty.map(data => ({
        userId: this.userId || '',
        id: data.id || 0,
        parkingSeasonDifficultyMonthId: data?.overnightParkingSeasonDifficulty?.id || 0,
        parkingSeasonDifficultyMonthName: data?.overnightParkingSeasonDifficulty?.name || '',
      })),
      appliedParkingAlternates: this.appliedParkingAlternates.map(data => ({
        userId: this.userId || '',
        id: data.id || 0,
        airportReferenceId: data?.airportReference?.airportId || 0,
      })),
    };
  }

  public static getMonthsList() {
    return SettingBaseModel.deserializeList([
      {
        id: 1,
        name: 'January',
      },
      {
        id: 2,
        name: 'February',
      },
      {
        id: 3,
        name: 'March',
      },
      {
        id: 4,
        name: 'April',
      },
      {
        id: 5,
        name: 'May',
      },
      {
        id: 6,
        name: 'June',
      },
      {
        id: 7,
        name: 'July',
      },
      {
        id: 8,
        name: 'August',
      },
      {
        id: 9,
        name: 'September',
      },
      {
        id: 10,
        name: 'October',
      },
      {
        id: 11,
        name: 'November',
      },
      {
        id: 12,
        name: 'December',
      },
    ]);
  }

  static deserialize(apiData: ParkingSeasonalityModel): ParkingSeasonalityModel {
    if (!apiData) {
      return new ParkingSeasonalityModel();
    }
    const data: Partial<ParkingSeasonalityModel> = {
      ...apiData,
      status: SettingBaseModel.deserialize(apiData.status),
      vendorLocation: VendorLocationModel.deserialize(apiData.vendorLocation),
      airportReference: Airports.deserializeAirportReference(apiData.airportReference),
      appliedParkingSeasonDifficulty: ParkingSeasonalityOptionModel.deserializeList(
        apiData.appliedParkingSeasonDifficulty
      ),
      appliedParkingAlternates: ParkingSeasonalityOptionModel.deserializeList(apiData.appliedParkingAlternates),
      overnightParkingDifficulty: SettingBaseModel.deserialize({
        id: apiData.overnightParkingDifficultyId,
        name: apiData.overnightParkingDifficultyName,
      }),
    };
    return new ParkingSeasonalityModel(data);
  }

  static deserializeList(apiDataList: ParkingSeasonalityModel[]): ParkingSeasonalityModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => ParkingSeasonalityModel.deserialize(apiData)) : [];
  }

  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
