import { createStyles, Theme } from '@material-ui/core';
import { pad } from 'lodash-es';

export const styles = createStyles((theme: Theme) => ({
  headerActions: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #D3D3D3',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    paddingBottom: '12px',
    '& button': {
      width: '150px',
      marginRight: theme.spacing(1),
    },
    '& h5': {
      fontSize: '1.625rem',
    },
    '& .MuiTypography-h6': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'normal',
      whiteSpace: 'nowrap',
      fontSize: '1.625rem',
    },
  },
  imageIcon: {
    height: '16px',
    width: '16px',
    cursor: 'pointer',
    color: '#1976D2',
    pointerEvents: 'all',
    marginLeft: theme.spacing(1),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  customHeight: {
    height: 'calc(100vh - 290px)',
  },
  radio: {
    paddingBottom: '10px',
  },
  maximumStay: {
    fontSize: '12px',
    fontWeight: '600',
    paddingBottom: '15px',
  },
  editorWrapperContainer: {
    overflow: 'auto',
    '& .AutoCompleteControl-multiple-61.AutoCompleteControl-multiple-61[class*="MuiOutlinedInput-root"]'
    : {
      paddingLeft: '10px !important',
    },
  },
  defaultButton: {
    marginLeft: '10px',
    '& button': {
      border: '1px solid #1976D2',
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      boxShadow: 'none',
      fontWeight: '400',
      width: '140px',
      textTransform: 'capitalize',
      color: '#1976D2',
      marginRight: '0',
    },
  },
  primaryButton: {
    '& button': {
      background: '#1976D2',
      color: '#fff',
    },
    '& button:disabled': {
      background: '#afafaf',
      border: 'none',
    },
    '& button:hover': {
      background: '#63A4FF',
      boxShadow: 'none',
    },
  },
  groupInputControls: {
    '& .makeStyles-flexRow-31': {
      paddingBottom: '10px',
    },
  },
  label: {
    fontSize: '12px',
    fontWeight: '600',
  },
  flexIcon: {
    display: 'flex',
    marginBottom: '5px',
    fontSize: '12px',
    fontWeight: '600',
  },
  parkingSeasonclass:{
    '& .MuiOutlinedInput-root': {
      paddingLeft: '10px !important',
    },
  },
  parkingwidthhere: {
    width: '98%',
    '& .MuiOutlinedInput-root': {
      paddingLeft: '10px !important',
    },
  },
  error: {
    paddingTop: '22px',
  },
  textDateTime: {
    paddingBottom: '30px',
    width: '98%',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    '& .MuiOutlinedInput-root': {
      height: '40px',
    },
    '& .MuiInputLabel-outlined': {
      fontSize: '10px',
      fontWeight: '400',
    },
  },
  chip: {
    backgroundColor: '#004ba0',
  },
}));
