import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { AuthStore } from '@uplink-shared/security';
import { VendorManagmentModel } from '../VendorManagment.model';
import { VendorLocationModel } from '../VendorLocation.model';

@modelProtection
export class VendorOnBoardSlideEightModel extends CoreModel implements ISelectOption {
  id: number = 0;
  vendor: VendorManagmentModel = new VendorManagmentModel();
  vendorId: number = 0;
  tempLocationId: string = '';
  coordinatingOffice: VendorLocationModel = new VendorLocationModel();
  legalBusinessName: string = '';
  vendorName: string = '';
  managerName: string = '';
  assitManagerName: string = '';
  opsPrimaryPhoneNo: string = '';
  opsSecondaryPhoneNo: string = '';
  opsFaxNo: string = '';
  opsPrimaryEmail: string = '';
  opsSecondaryEmail: string = '';

  constructor(data?: Partial<VendorOnBoardSlideEightModel>) {
    super(data);
    Object.assign(this, data);
  }

  public serialize(): VendorOnBoardSlideEightModel {
    return {
      id: this.id || 0,
      userId: this.userId,
      vendorId: AuthStore.vendorProfile.id,
      tempLocationId: this.tempLocationId ? this.tempLocationId : null,
      coordinatingOfficeId: this.coordinatingOffice.vendorLocationId || null,
      legalBusinessName: this.legalBusinessName,
      vendorName: this.vendorName,
      assitManagerName: this.assitManagerName,
      managerName: this.managerName,
      opsPrimaryPhoneNo: this.opsPrimaryPhoneNo,
      opsSecondaryPhoneNo: this.opsSecondaryPhoneNo,
      opsFaxNo: this.opsFaxNo,
      opsPrimaryEmail: this.opsPrimaryEmail,
      opsSecondaryEmail: this.opsSecondaryEmail,
    };
  }

  static deserialize(apiData: VendorOnBoardSlideEightModel): VendorOnBoardSlideEightModel {
    if (!apiData) {
      return new VendorOnBoardSlideEightModel();
    }
    const data: Partial<VendorOnBoardSlideEightModel> = {
      ...apiData,
      vendor: VendorManagmentModel.deserialize(apiData.vendor),
      coordinatingOffice:
        apiData.coordinatingOffice?.vendorLocationId !== 0
          ? VendorLocationModel.deserialize(apiData.coordinatingOffice)
          : null,
    };
    return new VendorOnBoardSlideEightModel(data);
  }

  static deserializeList(apiDataList: VendorOnBoardSlideEightModel[]): VendorOnBoardSlideEightModel[] {
    return apiDataList ? apiDataList.map(apiData => VendorOnBoardSlideEightModel.deserialize(apiData)) : [];
  }

  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
