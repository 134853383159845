import React, { FC, ReactNode, useEffect, useState } from 'react';
import { CabotageStore, SettingsStore, VendorManagementStore } from '../../../../Stores';
import { useUnsubscribe } from '@wings-shared/hooks';
import { CountryModel, NO_SQL_COLLECTIONS, useBaseUpsertComponent } from '@uplink/shared';
import {
  UIStore,
  IAPISearchFiltersDictionary,
  IOptionValue,
  IClasses,
  IAPIGridRequest,
  IAPIPageResponse,
} from '@wings-shared/core';
import { forkJoin } from 'rxjs';
import {
  SettingBaseModel,
  ViewInputControls,
  SidebarMenuOperationalInfo,
  COLLECTION_NAMES,
  AppliedDistinctModel,
  CustomDialog,
  CabotageModel,
} from '../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { ConfirmNavigate, DetailsEditorWrapper, RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { Box, Button, Switch, Typography, withStyles } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { styles } from './Cabotage.styles';
import { fields } from './Fields';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import { ModalStore } from '@uvgo-shared/modal-keeper';

interface Props {
  classes?: IClasses;
  settingsStore: SettingsStore;
  cabotageStore: CabotageStore;
  vendorManagementStore: VendorManagementStore;
  searchFilters: IAPISearchFiltersDictionary;
}

const Cabotage: FC<Props> = ({ settingsStore, cabotageStore, vendorManagementStore, classes, searchFilters }) => {
  const unsubscribe = useUnsubscribe();
  const navigate = useNavigate();
  const [ isCabotageEnforced, setIsCabotageEnforced ] = useState(false);
  const useUpsert = useBaseUpsertComponent<CabotageModel>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const [ countryList, setCountryList ] = useState<CountryModel[]>([]);
  const gridState = useGridState();

  useEffect(() => {
    SidebarStore.setNavLinks(
      SidebarMenuOperationalInfo(),
      'vendor',
      'Operational Information',
      '/vendor/locations',
      'panel2'
    );
    loadDropDownData();
    cabotageStore.formValueChanged = false;
    cabotageStore.countryOrRegionError = false;
  }, []);

  const loadDropDownData = () => {
    UIStore.setPageLoader(true);
    forkJoin([
      settingsStore.getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.REGION),
      settingsStore.getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.FARTYPE),
      vendorManagementStore.getVmsCountryCode(),
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: [SettingBaseModel[], SettingBaseModel[], IAPIPageResponse<CountryModel>]) => {
        loadInitialData();
        setCountryList(response[2].results);
      });
  };

  const setFormValues = (data: CabotageModel) => {
    const item = CabotageModel.deserialize(data);
    useUpsert.setFormValues(item);
    if (item.cabotageExemptionLevel?.id === 1) {
      useUpsert
        .getField('cabotageExemptionLevel')
        .set(SettingBaseModel.deserialize(CabotageModel.getCabotageApplyList()[0]));
      if (item.appliedCountryCabotageExemptions.length === 0) {
        cabotageStore.countryOrRegionError = true;
      } else {
        cabotageStore.countryOrRegionError = false;
      }
    } else if (item.cabotageExemptionLevel?.id === 2) {
      useUpsert
        .getField('cabotageExemptionLevel')
        .set(SettingBaseModel.deserialize(CabotageModel.getCabotageApplyList()[1]));
      if (item.appliedRegionCabotageExemptions.length === 0) {
        cabotageStore.countryOrRegionError = true;
      } else {
        cabotageStore.countryOrRegionError = false;
      }
    } else {
      useUpsert
        .getField('cabotageExemptionLevel')
        .set(SettingBaseModel.deserialize(CabotageModel.getCabotageApplyList()[0]));
      if (item.appliedCountryCabotageExemptions.length === 0) {
        cabotageStore.countryOrRegionError = true;
      } else {
        cabotageStore.countryOrRegionError = false;
      }
    }
    cabotageStore.formValueChanged = false;
    setIsCabotageEnforced(data.isCabotageEnforced);
    useUpsert.getField('isImportationFeesforDomesticFlight').set(data.isImportationFeesforDomesticFlight ? 1 : 2);
    useUpsert.getField('isCustomsStopsExempt').set(data.isCustomsStopsExempt ? 1 : 2);
    useUpsert.getField('isPaxMustDepartwithSameOperator').set(data.isPaxMustDepartwithSameOperator ? 1 : 2);
    useUpsert.getField('isNoNewPaxAllowedtoDepart').set(data.isNoNewPaxAllowedtoDepart ? 1 : 2);
    useUpsert.getField('isCabotageAppliestoLivestock').set(data.isCabotageAppliestoLivestock ? 1 : 2);
    useUpsert.getField('isCabotageAppliestoCargo').set(data.isCabotageAppliestoCargo ? 1 : 2);
    useUpsert.getField('isCabotageAppliestoNonResidents').set(data.isCabotageAppliestoNonResidents ? 1 : 2);
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: RootDataStore.locationData.locationId,
        },
      ]),
    };
    cabotageStore
      .getCabotageData(COLLECTION_NAMES.CABOTAGE, request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: CabotageModel[]) => {
        if (response.results.length > 0) {
          const data = response.results[0];
          setFormValues(data);
        }
      });
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    cabotageStore.formValueChanged = true;
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'appliedDisinsectionCountries':
        setCountryList(vendorManagementStore.countries);
        break;
      case 'cabotageExemptionLevel':
        const cabotageCountryValue = useUpsert.getField('appliedCountryCabotageExemptions').value;
        const cabotageRegionValue = useUpsert.getField('appliedRegionCabotageExemptions').value;
        if (value?.id === 1) {
          if (cabotageCountryValue?.length != 0) {
            cabotageStore.countryOrRegionError = false;
          } else {
            cabotageStore.countryOrRegionError = true;
          }
        } else if (value?.id === 2) {
          if (cabotageRegionValue?.length != 0) {
            cabotageStore.countryOrRegionError = false;
          } else {
            cabotageStore.countryOrRegionError = true;
          }
        }
        break;
      case 'appliedRegionCabotageExemptions':
        if (value?.length > 0) {
          cabotageStore.countryOrRegionError = false;
        } else {
          cabotageStore.countryOrRegionError = true;
        }
        break;
      case 'appliedCountryCabotageExemptions':
        if (value?.length > 0) {
          cabotageStore.countryOrRegionError = false;
        } else {
          cabotageStore.countryOrRegionError = true;
        }
        break;
      default:
        break;
    }
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'appliedDisinsectionCountries':
        const filteredList = vendorManagementStore.countries.filter(country => {
          return (
            country.commonName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            country.isO2Code?.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        setCountryList(filteredList);
        break;
      default:
        break;
    }
    return;
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'Aircraft Logistics:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'vendorLocation',
            type: EDITOR_TYPES.DROPDOWN,
            isHidden: true,
          },
          {
            fieldKey: 'cabotageExemptionLevel',
            type: EDITOR_TYPES.DROPDOWN,
            options: CabotageModel.getCabotageApplyList(),
            isHalfFlex: true,
            disableCloseOnSelect: true,
          },
          {
            fieldKey: 'appliedCountryCabotageExemptions',
            type: EDITOR_TYPES.DROPDOWN,
            options: cabotageStore.getCabotageSettingOptions<CountryModel>(vendorManagementStore.countries, 'country'),
            multiple: true,
            isHalfFlex: true,
            disableCloseOnSelect: true,
            isHidden: useUpsert.getField('cabotageExemptionLevel').value?.id === 2,
            customErrorMessage:
              useUpsert.getField('cabotageExemptionLevel').value?.id === 1 && cabotageStore.countryOrRegionError
                ? 'This Cabotage Associated Entities* is required'
                : '',
          },
          {
            fieldKey: 'appliedRegionCabotageExemptions',
            type: EDITOR_TYPES.DROPDOWN,
            options: cabotageStore.getCabotageSettingOptions<SettingBaseModel>(
              settingsStore.reffDataRegionExemptionList,
              'region'
            ),
            multiple: true,
            isHalfFlex: true,
            disableCloseOnSelect: true,
            isHidden: useUpsert.getField('cabotageExemptionLevel').value?.id === 1,
            customErrorMessage:
              useUpsert.getField('cabotageExemptionLevel').value?.id === 2 && cabotageStore.countryOrRegionError
                ? 'This Cabotage Associated Entities* is required'
                : '',
          },
          {
            fieldKey: 'cabotageEnforcedForFARTypes',
            type: EDITOR_TYPES.DROPDOWN,
            options: cabotageStore.getCabotageSettingOptions<SettingBaseModel>(
              settingsStore.reffDataEnforcedForFARTypeList,
              'farType'
            ),
            multiple: true,
            isFullFlex: true,
            disableCloseOnSelect: true,
          },
          {
            fieldKey: 'isImportationFeesforDomesticFlight',
            type: EDITOR_TYPES.RADIO,
            showLabel: true,
            selectControlOptions: CabotageModel.getYesNo(),
            defaultValue: 1,
          },
          {
            fieldKey: 'isCustomsStopsExempt',
            type: EDITOR_TYPES.RADIO,
            showLabel: true,
            selectControlOptions: CabotageModel.getYesNo(),
            defaultValue: 1,
          },
          {
            fieldKey: 'isPaxMustDepartwithSameOperator',
            type: EDITOR_TYPES.RADIO,
            showLabel: true,
            selectControlOptions: CabotageModel.getYesNo(),
            defaultValue: 1,
          },
          {
            fieldKey: 'isNoNewPaxAllowedtoDepart',
            type: EDITOR_TYPES.RADIO,
            showLabel: true,
            selectControlOptions: CabotageModel.getYesNo(),
            defaultValue: 1,
          },
          {
            fieldKey: 'isCabotageAppliestoLivestock',
            type: EDITOR_TYPES.RADIO,
            showLabel: true,
            selectControlOptions: CabotageModel.getYesNo(),
            defaultValue: 1,
          },
          {
            fieldKey: 'isCabotageAppliestoCargo',
            type: EDITOR_TYPES.RADIO,
            showLabel: true,
            selectControlOptions: CabotageModel.getYesNo(),
            defaultValue: 1,
          },
          {
            fieldKey: 'isCabotageAppliestoNonResidents',
            type: EDITOR_TYPES.RADIO,
            showLabel: true,
            selectControlOptions: CabotageModel.getYesNo(),
            defaultValue: 1,
          },
        ],
      },
    ];
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case 'appliedDisinsectionCountries':
        setCountryList(vendorManagementStore.countries);
        break;
      default:
        break;
    }
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const isInValid = (): boolean => {
    if (isCabotageEnforced) {
      return !formRef.isValid || formRef.hasError || !formRef.changed || cabotageStore.countryOrRegionError;
    } else {
      return !cabotageStore.formValueChanged || cabotageStore.countryOrRegionError;
    }
  };

  const getConfirmation = (): void => {
    ModalStore.open(
      <CustomDialog
        title="Confirm changes"
        message={'Canceling will lost your changes. Are you sure you want to cancel?'}
        yesButton="Confirm"
        noButton="Cancel"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => {
          navigate('/vendor/locations');
        }}
      />
    );
  };

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5">Aircraft Spray Requirements</Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => getConfirmation()}
              disabled={!cabotageStore.formValueChanged}
              size="large"
            >
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={isInValid()}
              onClick={() => upsertCabotage()}
            >
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  };

  const upsertCabotage = (): void => {
    const request = new CabotageModel({ ...useUpsert.form.values(), isCabotageEnforced: isCabotageEnforced });
    UIStore.setPageLoader(true);
    cabotageStore
      ?.upsertCabotage(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: CabotageModel) => {
          useUpsert.form.reset();
          setFormValues(CabotageModel.deserialize(response));
        },
        error: error => {
          if (error.response?.data?.errors) {
            errorHandler(error.response?.data?.errors, request.id.toString());
            return;
          }
          useUpsert.showAlert(error?.message, request.id.toString());
        },
      });
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cabotageStore.formValueChanged = true;
    setIsCabotageEnforced(Boolean(event.target.checked));
  };

  return (
    <>
      <DetailsEditorWrapper
        headerActions={headerActions()}
        isEditMode={true}
        classes={{ headerActions: classes.headerActions }}
      >
        <div className={classes.editorWrapperContainer}>
          <Typography className={classes.heading}>Is Cabotage Enforced?</Typography>
          <div className={classes.toggle}>
            <Typography>No</Typography>
            <Switch checked={isCabotageEnforced} onChange={handleSwitchChange} color="primary" name="switch" />
            <Typography>Yes</Typography>
          </div>
          {isCabotageEnforced && (
            <ViewInputControls
              isEditable={true}
              groupInputControls={groupInputControls()}
              onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
              onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
              field={fieldKey => useUpsert.getField(fieldKey)}
              onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
              onFocus={fieldKey => onFocus(fieldKey)}
            />
          )}
        </div>
      </DetailsEditorWrapper>
    </>
  );
};

export default inject(
  'settingsStore',
  'cabotageStore',
  'vendorManagementStore'
)(withStyles(styles)(observer(Cabotage)));
