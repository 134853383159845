import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { BaseStore, BulletinStore, VendorLocationStore } from '../../../../Stores';
import { IAPIGridRequest, IAPIPageResponse, IClasses, SearchStore, UIStore, MODEL_STATUS } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { CircularProgress, withStyles } from '@material-ui/core';
import {
  CustomHeader,
  SidebarMenuOperationalInfo,
  HandlerBulletinsModel,
  VENDOR_LOCATION_DATA_FILTER,
  CustomButton,
  InfiniteScroll,
  CustomList,
  IAPIVMSVendorLocationComparison,
  VendorLocationModel,
  CustomDialog,
  COLLECTION_NAMES,
} from '../../../Shared';
import { ISearchHeaderRef, SearchHeaderV2 } from '@uplink-shared/form-controls';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { useNavigate } from 'react-router';
import { useAgGrid, useGridState } from '@uplink-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { AnalyticsStore } from '@uplink-shared/analytics';
import { MixPanelTrackingEvents } from '@uplink/shared';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { finalize, takeUntil } from 'rxjs/operators';
import { styles } from './CountryNotes.styles';
import { gridFilters } from '../../../VendorLocations/Fields';
import { ModalStore } from '@uvgo-shared/modal-keeper';

interface Props {
  vendorLocationStore: VendorLocationStore;
  bulletinStore: BulletinStore;
  classes: IClasses;
}

const CountryNotes: FC<Props> = ({ vendorLocationStore, classes, bulletinStore }) => {
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const navigate = useNavigate();
  const gridState = useGridState();
  const agGrid = useAgGrid<VENDOR_LOCATION_DATA_FILTER, HandlerBulletinsModel>(gridFilters, gridState);
  const unsubscribe = useUnsubscribe();
  const [ isSpinnerVisible, setIsSpinnerVisible ] = useState(true);

  useEffect(() => {
    loadLocationAddress();
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_LOCATION_BULLETINS, {
      VendorLocationId: RootDataStore?.locationData?.locationId,
    });
    SidebarStore.setNavLinks(SidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
    vendorLocationStore.hasDataLoaded = true;
  }, []);

  useEffect(() => {
    if (!RootDataStore.locationData.countryDataManagement) {
      navigate('/vendor/locations');
    }
  }, [ RootDataStore.locationData.countryDataManagement ]);

  const loadLocationAddress = () => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: RootDataStore.locationData.locationId,
        },
      ]),
    };
    bulletinStore
      .getVMSComparison(COLLECTION_NAMES.VENDOR_LOCATION_ADDRESS, request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe(response => {
        const results = response.results;
        if (results.length === 0) {
          addAddressesNotification();
          setIsSpinnerVisible(false);
        }
        return;
      });
  };

  const rightContent = (): ReactNode => {
    return (
      <div className={classes.buttonContainer}>
        <CustomButton
          variant="contained"
          startIcon={<AddRoundedIcon />}
          to={'/vendor/location/country/notes/new'}
          title="Add Note"
        />
      </div>
    );
  };

  const searchCollection = (): IAPIGridRequest | null => {
    const propertyValue = getSearchValue();
    if (!propertyValue) {
      vendorLocationStore.hasDataLoaded = true;
      return null;
    }
    const filters = [
      {
        propertyName: 'BulletinTypeName',
        propertyValue: propertyValue,
        filterType: 'string',
      },
      {
        propertyName: 'BulletinText',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
      {
        propertyName: 'NotamNumber',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
    ];
    return {
      searchCollection: JSON.stringify(filters),
    };
  };

  const getSearchValue = (): string => {
    const searchHeader = searchHeaderRef.current?.getFilters();
    if (!searchHeader) {
      return null;
    }
    return searchHeader.searchValue || '';
  };

  const setSearchData = (): void => {
    const searchHeaderFilter = searchHeaderRef.current.getFilters();
    SearchStore.searchData.set(location.pathname, {
      searchValue: searchHeaderFilter?.searchValue,
      selectInputsValues: searchHeaderFilter?.selectInputsValues,
      chipValue: searchHeaderFilter.chipValue,
      pagination: gridState.pagination,
    });
  };

  const loadInitialData = (pageRequest?: IAPIGridRequest) => {
    if (bulletinStore.locationAddress.length > 0) {
      UIStore.setPageLoader(true);
      const request: IAPIGridRequest = {
        pageNumber: gridState.pagination.pageNumber,
        pageSize: gridState.pagination.pageSize,
        ...searchCollection(),
        ...agGrid.filtersApi.gridSortFilters(),
        filterCollection: JSON.stringify([
          { propertyName: 'BulletinLevelName', propertyValue: [ 'Country', 'State' ], filterType: 'in' },
          {
            propertyName: 'BulletinEntityId',
            propertyValue: [
              bulletinStore.locationAddress[0].countryReference.countryId,
              bulletinStore.locationAddress[0].stateReference.stateId,
            ],
            filterType: 'in',
            operator: 'and',
          },
          {
            propertyName: 'Status.Id',
            propertyValue: 1,
            operator: 'and',
          },
        ]),
        ...pageRequest,
      };
      bulletinStore
        .getLocationAllBulletins(request)
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => {
            UIStore.setPageLoader(false);
          })
        )
        .subscribe((response: IAPIPageResponse<IAPIVMSVendorLocationComparison>) => {
          const results = response.results;
          if (results.length === 0) {
            vendorLocationStore.hasDataLoaded = false;
            return;
          }
          if (response.pageNumber === 1) {
            gridState.setGridData([]);
          }
          gridState.setGridData([ ...gridState.data, ...results ]);
        });
    }
  };

  const colDefNew = [
    {
      headerName: 'Type',
      field: 'bulletinType.name',
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
    },
    {
      headerName: 'End Date',
      field: 'endDate',
    },
    {
      headerName: 'Notam',
      field: 'notamNumber',
    },
    {
      headerName: 'Bulletin Text',
      field: 'bulletinText',
    },
    {
      field: 'actionRenderer',
      headerName: '\u00A0\u00A0\u00A0\u00A0Edit\u00A0\u00A0\u00A0\u00A0',
    },
  ];

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => BaseStore.showAlert(errorMessage[0], id));
  };

  const upsertCountryBulletin = (item): void => {
    UIStore.setPageLoader(true);
    const request = new HandlerBulletinsModel({ ...item });
    const entityValue = {
      id: request.bulletinEntity.id,
      code: request.bulletinEntity.code,
      name: request.bulletinEntity.name,
    };
    bulletinStore
      ?.upsertVendorLocationHanlderBulletin(request.serialize(entityValue, MODEL_STATUS.IN_ACTIVE))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: HandlerBulletinsModel) => {
          gridState.setGridData([]);
          loadInitialData({ pageNumber: 1 });
        },
        error: error => {
          errorHandler(error.response?.data?.errors, request.id.toString());
        },
      });
  };

  const onEdit = (item, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchData();
    navigate(`/vendor/location/country/notes/edit/${item?.id}`);
  };

  const getDeleteConfirmation = (item): void => {
    ModalStore.open(
      <CustomDialog
        title="Remove this Note"
        message={'Are you sure you want to remove this note?'}
        yesButton="Confirm"
        noButton="Cancel"
        onNoClick={() => {
          ModalStore.close();
        }}
        onYesClick={() => upsertCountryBulletin(item)}
      />
    );
  };

  const onDelete = (item, e) => {
    getDeleteConfirmation(item);
  };

  const addAddressesNotification = (): void => {
    ModalStore.open(
      <CustomDialog
        title="Add Address"
        message={'There is no <strong>Physical Address</strong> for this location, please add'}
        yesButton="Click Here"
        noButton="Cancel"
        onNoClick={() => {
          ModalStore.close();
          navigate('/vendor/locations');
        }}
        onYesClick={() => {
          ModalStore.close();
          navigate('/vendor/location/addresses');
        }}
      />
    );
  };

  const onDetails = (item, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchData();
    navigate(`/vendor/location/country/notes/details/${item?.id}`);
  };

  return (
    <>
      <CustomHeader title={'Country Notes'} />
      <SearchHeaderV2
        placeHolder="Search"
        ref={searchHeaderRef}
        selectInputs={[]}
        isUsingSelectInputs={false}
        onResetFilterClick={() => {
          gridState.setGridData([]);
          loadInitialData({ pageNumber: 1 });
        }}
        rightContent={rightContent}
        onFilterChange={isInitEvent => {
          gridState.setGridData([]);
          vendorLocationStore.hasDataLoaded = true;
          loadInitialData({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 });
        }}
        isLoading={UIStore.pageLoading}
      />
      <InfiniteScroll
        pageStart={0}
        loadMore={page => {
          const searchData = SearchStore.searchData.get(location.pathname);
          if (searchData) {
            searchHeaderRef.current?.setupDefaultFilters(searchData);
            SearchStore.clearSearchData(location.pathname);
          }
          setTimeout(() => {
            loadInitialData({ pageNumber: page });
          }, 300);
        }}
        resetPagination={isSpinnerVisible}
        hasMore={vendorLocationStore.hasDataLoaded && !UIStore.pageLoading}
        loader={<div style={{ textAlign: 'center' }}>{isSpinnerVisible && <CircularProgress />}</div>}
        useWindow={false}
      >
        <CustomList
          classes={classes}
          colDef={colDefNew}
          rowData={gridState.data}
          isHeaderVisible={false}
          onEdit={(item, e) => onEdit(item, e)}
          onDetails={(item, e) => onDetails(item, e)}
          onDelete={(item, e) => onDelete(item, e)}
          selectedItemId={RootDataStore.locationData.locationId}
          isLoading={UIStore.pageLoading}
          isAirportBulletins={true}
          showDetailButton={false}
          showEditButton={true}
          showDeleteButton={true}
        />
      </InfiniteScroll>
    </>
  );
};

export default inject('vendorLocationStore', 'bulletinStore')(withStyles(styles)(observer(CountryNotes)));
