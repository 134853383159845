import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { BulletinStore, VendorLocationStore } from '../../../../Stores';
import { IAPIGridRequest, IAPIPageResponse, IClasses, SearchStore, UIStore } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { CircularProgress, withStyles } from '@material-ui/core';
import {
  CustomHeader,
  SidebarMenuOperationalInfo,
  HandlerBulletinsModel,
  VENDOR_LOCATION_DATA_FILTER,
  CustomButton,
  InfiniteScroll,
  CustomList,
  IAPIVMSVendorLocationComparison,
  VendorLocationModel,
} from '../../../Shared';
import { ISearchHeaderRef, SearchHeaderV2 } from '@uplink-shared/form-controls';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { useNavigate, useParams } from 'react-router';
import { useAgGrid, useGridState } from '@uplink-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { AnalyticsStore } from '@uplink-shared/analytics';
import { MixPanelTrackingEvents } from '@uplink/shared';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { finalize, takeUntil } from 'rxjs/operators';
import { styles } from './Notes.styles';
import { gridFilters } from '../../../VendorLocations/Fields';

interface Props {
  vendorLocationStore: VendorLocationStore;
  bulletinStore: BulletinStore;
  classes: IClasses;
}

const Notes: FC<Props> = ({ vendorLocationStore, classes, bulletinStore }) => {
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const navigate = useNavigate();
  const gridState = useGridState();
  const agGrid = useAgGrid<VENDOR_LOCATION_DATA_FILTER, HandlerBulletinsModel>(gridFilters, gridState);
  const unsubscribe = useUnsubscribe();
  const [ airportDataManagement, setAirportDataManagement ] = useState(false);
  const params = useParams();
  const isAirport = params.type === 'airport';

  useEffect(() => {
    loadVendorLocationData();
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_LOCATION_BULLETINS);
    SidebarStore.setNavLinks(SidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
    vendorLocationStore.hasDataLoaded = true;
  }, [ airportDataManagement ]);

  const loadVendorLocationData = () => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVendorLocationById(RootDataStore.locationData.locationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorLocationModel) => {
        if (response) {
          setAirportDataManagement(response.airportDataManagement);
          RootDataStore.setLocation(
            true,
            response.id,
            response.name,
            response.code,
            response.airportReference?.id,
            '',
            response.airportDataManagement,
            response.countryDataManagement,
            response.permitDataManagement
          );
        }
      });
  };

  const rightContent = (): ReactNode => {
    return (
      <div className={classes.buttonContainer}>
        <CustomButton
          variant="contained"
          startIcon={<AddRoundedIcon />}
          to={`/vendor/location/${params.type}/notes/new`}
          title="Add Note"
        />
      </div>
    );
  };

  const searchCollection = (): IAPIGridRequest | null => {
    const propertyValue = getSearchValue();
    if (!propertyValue) {
      vendorLocationStore.hasDataLoaded = true;
      return null;
    }
    const filters = [
      {
        propertyName: 'AppliedBulletinTypes.BulletinType.Name',
        propertyValue: propertyValue,
        filterType: 'string',
      },
      {
        propertyName: 'BulletinText',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
      {
        propertyName: 'NotamNumber',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
    ];
    return {
      searchCollection: JSON.stringify(filters),
    };
  };

  const getSearchValue = (): string => {
    const searchHeader = searchHeaderRef.current?.getFilters();
    if (!searchHeader) {
      return null;
    }
    return searchHeader.searchValue || '';
  };

  const setSearchData = (): void => {
    const searchHeaderFilter = searchHeaderRef.current.getFilters();
    SearchStore.searchData.set(location.pathname, {
      searchValue: searchHeaderFilter?.searchValue,
      selectInputsValues: searchHeaderFilter?.selectInputsValues,
      chipValue: searchHeaderFilter.chipValue,
      pagination: gridState.pagination,
    });
  };

  const loadInitialData = (pageRequest?: IAPIGridRequest) => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      ...searchCollection(),
      ...agGrid.filtersApi.gridSortFilters(),
      filterCollection: JSON.stringify([
        {
          propertyName: 'BulletinEntityId',
          propertyValue: isAirport
            ? RootDataStore.locationData.airportReferenceId
            : RootDataStore.locationData.locationId,
        },
      ]),
      ...pageRequest,
    };
    bulletinStore
      .getLocationAllBulletins(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: IAPIPageResponse<IAPIVMSVendorLocationComparison>) => {
        const results = response.results;
        if (results.length === 0) {
          vendorLocationStore.hasDataLoaded = false;
          return;
        }
        if (response.pageNumber === 1) {
          gridState.setGridData([]);
        }
        gridState.setGridData([ ...gridState.data, ...results ]);
      });
  };

  const colDefNew = [
    {
      headerName: 'Type',
      field: 'bulletinType.name',
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
    },
    {
      headerName: 'End Date',
      field: 'endDate',
    },
    {
      headerName: 'Notam',
      field: 'notamNumber',
    },
    {
      headerName: 'Bulletin Text',
      field: 'bulletinText',
    },
    {
      field: 'actionRenderer',
      headerName: '\u00A0\u00A0\u00A0\u00A0Edit\u00A0\u00A0\u00A0\u00A0',
    },
  ];

  const onEdit = (item, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchData();
    navigate(`/vendor/location/${params.type}/notes/edit/${item?.id}`);
  };

  const onDetails = (item, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchData();
    navigate(`/vendor/location/${params.type}/notes/details/${item?.id}`);
  };

  return (
    <>
      <CustomHeader title={`${isAirport ? 'Airport' : 'Handler'} Notes`} />
      <SearchHeaderV2
        placeHolder="Search"
        ref={searchHeaderRef}
        selectInputs={[]}
        isUsingSelectInputs={false}
        onResetFilterClick={() => {
          gridState.setGridData([]);
          loadInitialData({ pageNumber: 1 });
        }}
        rightContent={rightContent}
        onFilterChange={isInitEvent => {
          gridState.setGridData([]);
          vendorLocationStore.hasDataLoaded = true;
          loadInitialData({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 });
        }}
        isLoading={UIStore.pageLoading}
      />
      <InfiniteScroll
        pageStart={0}
        loadMore={page => {
          const searchData = SearchStore.searchData.get(location.pathname);
          if (searchData) {
            searchHeaderRef.current?.setupDefaultFilters(searchData);
            SearchStore.clearSearchData(location.pathname);
          }
          setTimeout(() => {
            loadInitialData({ pageNumber: page });
          }, 300);
        }}
        hasMore={vendorLocationStore.hasDataLoaded && !UIStore.pageLoading}
        loader={
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        }
        useWindow={false}
      >
        <CustomList
          classes={classes}
          colDef={colDefNew}
          rowData={gridState.data}
          isHeaderVisible={false}
          onEdit={(item, e) => onEdit(item, e)}
          onDetails={(item, e) => onDetails(item, e)}
          selectedItemId={RootDataStore.locationData.locationId}
          isLoading={UIStore.pageLoading}
          isBulletins={true}
          showEditButton={true}
          showDeleteButton={false}
        />
      </InfiniteScroll>
    </>
  );
};

export default inject('vendorLocationStore', 'bulletinStore')(withStyles(styles)(observer(Notes)));
