import {
  GRID_ACTIONS,
  IAPIGridRequest,
  IAPISearchFiltersDictionary,
  IClasses,
  IOptionValue,
  SEARCH_ENTITY_TYPE,
  UIStore,
  Utilities,
  ViewPermission,
} from '@wings-shared/core';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { SettingsStore, VendorManagementStore } from '../../Stores';
import { NavigateFunction, useNavigate } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Box, Typography, withStyles } from '@material-ui/core';
import { styles } from './VendorGeneralInformation.styles';
import {
  CityModel,
  CountryModel,
  MixPanelTrackingEvents,
  StateModel,
  VIEW_MODE,
  useBaseUpsertComponent,
} from '@uplink/shared';
import { AuthStore } from '@uplink-shared/security';
import { useGridState } from '@wings-shared/custom-ag-grid';
import {
  SETTING_ID,
  StatusBaseModel,
  VendorManagmentModel,
  VendorModuleSecurity,
  VendorAddressModel,
  SidebarMenus,
  CustomTooltip,
  ViewInputControls,
} from '../Shared';
import { useUnsubscribe } from '@wings-shared/hooks';
import { fields } from './Fields';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  DetailsEditorHeaderSection,
  DetailsEditorWrapper,
  ConfirmNavigate,
  ConfirmDialog,
  SidebarStore,
} from '@uplink-shared/layout';
import { SettingNamesMapper } from '../../Stores/SettingsMapper';
import Button from '@material-ui/core/Button';
import { AnalyticsStore } from '@uplink-shared/analytics';
import { UserManagementStore } from '../../Stores/UserManagement.store';

interface Props {
  classes?: IClasses;
  settingsStore?: SettingsStore;
  vendorManagementStore?: VendorManagementStore;
  userManagementStore?: UserManagementStore;
  searchFilters?: IAPISearchFiltersDictionary;
  navigate?: NavigateFunction;
}

const VendorGeneralInformation: FC<Props> = ({
  classes,
  settingsStore,
  vendorManagementStore,
  userManagementStore,
  searchFilters,
}) => {
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();
  const useUpsert = useBaseUpsertComponent<VendorManagmentModel>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const navigate = useNavigate();
  const [ countryList, setCountryList ] = useState([]);
  const [ statesList, setStatesList ] = useState([]);
  const [ isSaved, setIsSaved ] = useState(false);
  const isEditable = VendorModuleSecurity.isEditable;

  useEffect(() => {
    SidebarStore.setNavLinks(SidebarMenus(), 'vendor');
    useUpsert.setViewMode(isEditable ? VIEW_MODE.EDIT : VIEW_MODE.DETAILS);
    vendorManagementStore.getVmsCountryCode().subscribe(response => {
      setCountryList(response.results);
    });
    if (AuthStore.vendorProfile) {
      loadInitialData();
      userManagementStore?.getUvGoAccessToken();
    }
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_GENERALINFORMATION);
  }, [ AuthStore.vendorProfile ]);

  const title = (): string => {
    if (useUpsert.getField('code').value) {
      return `${useUpsert.getField('name').value}`;
    }
    return `${useUpsert.getField('name').value}`;
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'General Information:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'name',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'code',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'vendorStatus',
            type: EDITOR_TYPES.DROPDOWN,
            options: settingsStore.vendorSettings,
            isHidden: true,
          },
          {
            fieldKey: 'legalCompanyName',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'addressTypeId',
            type: EDITOR_TYPES.DROPDOWN,
            isHidden: true,
          },
          {
            fieldKey: 'vendorAddressId',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'addressLine1',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: isFieldDisabled(),
          },
          {
            fieldKey: 'addressLine2',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: isFieldDisabled(),
          },
          {
            fieldKey: 'hqAddressCountry',
            type: EDITOR_TYPES.DROPDOWN,
            options: countryList,
            searchEntityType: SEARCH_ENTITY_TYPE.COUNTRY,
            isDisabled: isFieldDisabled(),
          },
          {
            fieldKey: 'hqAddressState',
            type: EDITOR_TYPES.DROPDOWN,
            options: statesList,
            isDisabled: !isCountrySelected() || isFieldDisabled(),
            searchEntityType: SEARCH_ENTITY_TYPE.STATE,
            getOptionLabel: state => (state as StateModel)?.label,
          },
          {
            fieldKey: 'hqAddressCity',
            type: EDITOR_TYPES.DROPDOWN,
            options: vendorManagementStore.cities,
            isDisabled: !isCountrySelected() || isFieldDisabled(),
          },
          {
            fieldKey: 'hqAddressZipCode',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: isFieldDisabled(),
          },
          {
            fieldKey: 'is3rdPartyLocation',
            type: EDITOR_TYPES.CHECKBOX,
          },
        ],
      },
    ];
  };

  const isFieldDisabled = () => {
    return useUpsert.getField('addressTypeId').value === 1;
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'hqAddressCountry':
        vendorManagementStore.cities = [];
        vendorManagementStore.states = [];
        useUpsert.getField('hqAddressState').clear();
        useUpsert.getField('hqAddressCity').clear();
        filterStateByCountry(value);
        setCountryList(vendorManagementStore.countries);
        loadCities('');
        break;
      case 'hqAddressState':
        vendorManagementStore.cities = [];
        useUpsert.getField('hqAddressCity').clear();
        break;
      case 'hqAddressCity':
        vendorManagementStore.cities = [];
        break;
      default:
        break;
    }
    gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
  };

  const filterStateByCountry = (value?: any) => {
    const filter = value
      ? JSON.stringify([
        {
          propertyName: 'Country.CountryId',
          propertyValue: value.id,
        },
      ])
      : '';

    const request: IAPIGridRequest = {
      filterCollection: filter,
    };
    vendorManagementStore.getVmsStates(request, undefined).subscribe(response => {
      setStatesList(response.results);
    });
  };

  const loadCities = (searchValue: string): void => {
    const countryId: number = useUpsert.getField('hqAddressCountry').value?.id;
    if (!countryId || !searchValue) {
      vendorManagementStore.cities = [];
      return;
    }
    const stateId: number = useUpsert.getField('hqAddressState').value?.id;
    const filters = stateId
      ? Utilities.getFilter('State.StateId', stateId)
      : Utilities.getFilter('Country.CountryId', countryId);

    const searchCityFilter = searchValue
      ? [
        {
          propertyName: 'CommonName',
          propertyValue: searchValue,
        },
        {
          propertyName: 'OfficialName',
          operator: 'or',
          propertyValue: searchValue,
        },
      ]
      : [];

    const filterCollection = [ filters ];
    const request: IAPIGridRequest = {
      filterCollection: JSON.stringify(filterCollection),
      searchCollection: JSON.stringify(searchCityFilter),
    };
    vendorManagementStore.getVmsCities(request).subscribe();
  };

  const isCountrySelected = (): boolean => {
    const { value } = useUpsert.getField('hqAddressCountry');
    return Boolean((value as CountryModel)?.id);
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'hqAddressCountry':
        const filteredList = vendorManagementStore.countries.filter(country => {
          return (
            country.commonName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            country.isO2Code?.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        setCountryList(filteredList);
        break;
      case 'hqAddressCity':
        loadCities(searchValue);
        break;
      case 'hqAddressState':
        const filteredStates = vendorManagementStore.states.filter(data => {
          return (
            data.commonName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            data.cappsName?.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        setStatesList(filteredStates);
        break;
      default:
        break;
    }
    return;
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case 'vendorStatus':
        settingsStore.getSettings(SETTING_ID.SETTINGS_VENDOR_STATUS).subscribe();
        break;
      case 'hqAddressState':
        const { value } = useUpsert.getField('hqAddressCountry');
        filterStateByCountry(value);
        break;
      case 'hqAddressCountry':
        setCountryList(vendorManagementStore.countries);
        break;
      case 'hqAddressCity':
        if (!useUpsert.getField('hqAddressCity').value) {
          vendorManagementStore.cities = [];
          useUpsert.getField('hqAddressCity').clear();
        }
        break;
      default:
        break;
    }
  };

  const loadInitialData = () => {
    const vendorId = AuthStore?.vendorProfile?.id;
    UIStore.setPageLoader(true);
    vendorManagementStore
      ?.getVendorById(vendorId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorManagmentModel) => {
        setFormValues(response);
      });
  };

  const setFormValues = response => {
    useUpsert.setFormValues(response);
    Object.keys(response).forEach(data => {
      if (data === 'vendorStatus') {
        const statusModel = new StatusBaseModel({
          id: response['vendorStatus'].id,
          name: response['vendorStatus'].name,
          userId: response['vendorStatus'].userId,
        });
        useUpsert.getField('vendorStatus').set(statusModel);
      }
      if (data === 'vendorAddress' && response[data].vendorAddressId !== 0) {
        const vendorAddress: VendorAddressModel = VendorAddressModel.deserialize(response[data]);
        if (vendorAddress) {
          const countryModel = new CountryModel({
            id: vendorAddress?.countryReference.countryId,
            commonName: vendorAddress?.countryReference?.name,
            isO2Code: vendorAddress?.countryReference?.code,
          });
          const stateModel = new StateModel({
            id: vendorAddress?.stateReference?.stateId,
            commonName: vendorAddress?.stateReference?.name,
            code: vendorAddress?.stateReference?.code,
          });
          const cityModel = new CityModel({
            id: vendorAddress?.cityReference?.id,
            cappsCode: vendorAddress?.cityReference?.cappsCode,
            commonName: vendorAddress?.cityReference?.commonName,
          });
          useUpsert.getField('addressTypeId').set(vendorAddress.addressTypeId);
          useUpsert.getField('vendorAddressId').set(vendorAddress.id);
          useUpsert.getField('hqAddressCountry').set(countryModel);
          useUpsert.getField('hqAddressState').set(stateModel);
          useUpsert.getField('hqAddressCity').set(cityModel);
          useUpsert.getField('hqAddressZipCode').set(vendorAddress?.zipCode);
          useUpsert.getField('addressLine1').set(vendorAddress?.addressLine1);
          useUpsert.getField('addressLine2').set(vendorAddress?.addressLine2);
        }
      }
    });
  };

  const getConfirmation = (): void => {
    if (formRef.changed) {
      ModalStore.open(
        <ConfirmDialog
          title="Confirm Changes"
          message={'Canceling will lost your changes. Are you sure you want to cancel?'}
          yesButton="Confirm"
          onNoClick={() => ModalStore.close()}
          onYesClick={() => {
            useUpsert.form.reset();
            loadInitialData();
            ModalStore.close();
          }}
        />
      );
    }
  };

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          {<CustomTooltip title={title()} />}
        </Typography>
        <ViewPermission hasPermission={VendorModuleSecurity.isEditable}>
          <Box sx={{ display: 'flex' }}>
            <div className={`${classes.defaultButton}`}>
              <Button
                color="primary"
                variant="outlined"
                disabled={!formRef.changed}
                onClick={() => getConfirmation()}
                size="large"
                hidden={!VendorModuleSecurity.isEditable}
              >
                Cancel
              </Button>
            </div>
            <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => upsertVendor()}
                size="large"
                disabled={!formRef.changed || !formRef.isValid || formRef.hasError}
                hidden={!VendorModuleSecurity.isEditable}
              >
                Save
              </Button>
            </div>
          </Box>
        </ViewPermission>
      </>
    );
  };

  const upsertVendor = (): void => {
    UIStore.setPageLoader(true);
    if (!isSaved) {
      const request = new VendorManagmentModel({ ...useUpsert.form.values() });
      setIsSaved(true);
      vendorManagementStore
        ?.upsertVendor(request.serialize())
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe({
          next: (response: VendorManagmentModel) => {
            setIsSaved(false);
            vendorManagementStore.selectedVendor = response;
            vendorManagementStore.countries = [];
            vendorManagementStore.states = [];
            vendorManagementStore.cities = [];
            useUpsert.form.reset();
            setFormValues(response);
            if (!request.id) {
              useUpsert.resetFormValidations(response, () => {
                navigate('/vendor');
                setFormValues(response);
              });
            }
          },
          error: error => {
            setIsSaved(false);
            useUpsert.showAlert(error.message, request.id);
          },
        });
    }
  };

  return (
    <ConfirmNavigate isBlocker={formRef.changed}>
      <DetailsEditorWrapper
        headerActions={headerActions()}
        isEditMode={isEditable}
        classes={{ headerActions: classes.headerActions }}
      >
        <div className={classes.editorWrapperContainer}>
          <ViewInputControls
            isEditable={isEditable}
            groupInputControls={groupInputControls()}
            onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
            onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
            field={fieldKey => useUpsert.getField(fieldKey)}
            onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
            onFocus={fieldKey => onFocus(fieldKey)}
          />
        </div>
      </DetailsEditorWrapper>
    </ConfirmNavigate>
  );
};

export default inject(
  'settingsStore',
  'vendorManagementStore',
  'userManagementStore'
)(withStyles(styles)(observer(VendorGeneralInformation)));
