import {
  IAPIGridRequest,
  IAPISearchFiltersDictionary,
  IClasses,
  IOptionValue,
  UIStore,
  Utilities,
} from '@wings-shared/core';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router';
import { inject, observer } from 'mobx-react';
import {
  Box,
  Button,
  debounce,
  FormControl,
  FormControlLabel,
  FormHelperText,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { styles } from './Parking.styles';
import { NO_SQL_COLLECTIONS, useBaseUpsertComponent } from '@uplink/shared';
import { AuthStore } from '@uplink-shared/security';
import { useGridState } from '@wings-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { AutoCompleteControl, AutoCompleteV2, EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { DetailsEditorWrapper, ConfirmNavigate, SidebarStore, RootDataStore } from '@uplink-shared/layout';
import { ParkingSeasonalityStore, SettingsStore, VendorLocationStore } from '../../../../Stores';
import { ParkingSeasonalityModel } from '../../../Shared/Models/ParkingSeasonality.model';
import { fields } from './Fields';
import {
  Airports,
  CustomDialog,
  SettingBaseModel,
  SidebarMenuOperationalInfo,
  VendorModuleSecurity,
  ViewInputControls,
} from '../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import InfoIcon from '@material-ui/icons/Info';
import { AlertStore } from '@uvgo-shared/alert';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { Autocomplete } from '@material-ui/lab';
import { set } from 'mobx';

interface Props {
  classes?: IClasses;
  settingsStore?: SettingsStore;
  vendorLocationStore: VendorLocationStore;
  parkingSeasonalityStore: ParkingSeasonalityStore;
  searchFilters?: IAPISearchFiltersDictionary;
  navigate?: NavigateFunction;
}

const Parking: FC<Props> = ({
  classes,
  settingsStore,
  vendorLocationStore,
  parkingSeasonalityStore,
  searchFilters,
}) => {
  const [ isDisable, setIsDisable ] = useState(true);
  const gridState = useGridState();
  const navigate = useNavigate();
  const unsubscribe = useUnsubscribe();
  const useUpsert = useBaseUpsertComponent<ParkingSeasonalityModel>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const [ mins, setMins ] = useState(0);
  const [ hrs, setHrs ] = useState(0);
  const [ days, setDays ] = useState(0);
  const [ weeks, setWeeks ] = useState(0);
  const [ airportAlternate, setAirportAlternate ] = useState<any>([]);
  const [ parkingError, setParkingError ] = useState('');
  const [ formChanged, setFormChanged ] = useState(false);

  useEffect(() => {
    SidebarStore.setNavLinks(SidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
    settingsStore.getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.OVERNIGHT_PARKING).subscribe();
    vendorLocationStore.getVmsIcaoCode().subscribe();
    loadInitialData();
  }, [ AuthStore.vendorProfile ]);

  const resetFields = () => {
    setMins(0);
    setHrs(0);
    setDays(0);
    setWeeks(0);
    setParkingError('');
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormChanged(true);
    const value = Number(event.target.value);
    const { selectedParkingSeasonality } = parkingSeasonalityStore;
    parkingSeasonalityStore.radioValue = value;
    value === 2
      ? selectedParkingSeasonality.appliedParkingSeasonDifficulty.length === 0
        ? useUpsert.getField('appliedParkingSeasonDifficulty').value.length > 0
          ? (resetFields(), setIsDisable(false))
          : (resetFields(), setIsDisable(true))
        : selectedParkingSeasonality.isMaximumStayAllowed !== (value === 2 ? false : true)
          ? (resetFields(), setIsDisable(false))
          : resetFields()
      : value === 1
        ? airportAlternate.length === 0
          ? (setIsDisable(true), setParkingError('This Specify Alternate Airport for Parking is required'))
          : selectedParkingSeasonality.appliedParkingSeasonDifficulty.length === 0
            ? useUpsert.getField('appliedParkingSeasonDifficulty').value.length > 0
              ? (setIsDisable(false), setParkingError(''))
              : setIsDisable(true)
            : (setIsDisable(false), setParkingError(''))
        : null;
  };

  const totalMins = () => {
    return weeks * 10080 + days * 1440 + hrs * 60 + mins;
  };

  const convertMinutes = totalMinutes => {
    let weeks = Math.floor(totalMinutes / 10080);
    if (weeks > 9) {
      totalMinutes -= 9 * 10080;
      weeks = 9;
    } else {
      totalMinutes -= weeks * 10080;
    }
    let days = Math.floor(totalMinutes / 1440);
    if (days > 9) {
      totalMinutes -= 9 * 1440;
      days = 9;
    } else {
      totalMinutes -= days * 1440;
    }
    let hours = Math.floor(totalMinutes / 60);
    if (hours > 999) {
      totalMinutes -= 999 * 60;
      hours = 999;
    } else {
      totalMinutes -= hours * 60;
    }
    let minutes = totalMinutes;
    if (minutes > 9999) {
      minutes = 9999;
    }
    setWeeks(weeks);
    setDays(days);
    setHrs(hours);
    setMins(minutes);
  };

  const getConfirmation = (): void => {
    ModalStore.open(
      <CustomDialog
        title="Confirm changes"
        message={'Canceling will lost your changes. Are you sure you want to cancel?'}
        yesButton="Confirm"
        noButton="Cancel"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => {
          loadInitialData();
        }}
      />
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormChanged(true);
    const isAppliedDifficultyEmpty =
      parkingSeasonalityStore.selectedParkingSeasonality.appliedParkingSeasonDifficulty.length === 0;
    const appliedDifficultyValueLength = useUpsert.getField('appliedParkingSeasonDifficulty').value.length > 0;
    isAppliedDifficultyEmpty
      ? appliedDifficultyValueLength
        ? setIsDisable(false)
        : setIsDisable(true)
      : setIsDisable(false);

    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, '');
    const { target } = event;
    switch (target.id) {
      case 'mins':
        inputValue ? setMins(+inputValue) : setMins(0);
        break;
      case 'hrs':
        inputValue ? setHrs(+inputValue) : setHrs(0);
        break;
      case 'days':
        inputValue ? setDays(+inputValue) : setDays(0);
        break;
      case 'weeks':
        inputValue ? setWeeks(+inputValue) : setWeeks(0);
        break;
    }
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'General Information:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'appliedParkingSeasonDifficulty',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: parkingSeasonalityStore.getParkingSeasonalitySettingOptions<SettingBaseModel>(
              ParkingSeasonalityModel.getMonthsList(),
              'overnightParkingSeasonDifficulty'
            ),
            isEditable: true,
            multiple: true,
            isFullFlex: true,
            showLabel: false,
            disableCloseOnSelect: true,
          },
          {
            fieldKey: 'overnightParkingDifficulty',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: settingsStore.overnightParking,
            showLabel: false,
            isFullFlex: true,
            customLabel: field => {
              return (
                <div className={classes.label}>
                  <span>Any Issues With Overnight Parking</span>
                </div>
              );
            },
          },
        ],
      },
    ];
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    setFormChanged(true);
    useUpsert.getField(fieldKey).set(value);

    const isAppliedDifficultyEmpty =
      parkingSeasonalityStore.selectedParkingSeasonality.appliedParkingSeasonDifficulty.length === 0;
    const appliedDifficultyValueLength = useUpsert.getField('appliedParkingSeasonDifficulty').value.length > 0;
    const appliedParkingSeasonDifficultyChanged =
      parkingSeasonalityStore.selectedParkingSeasonality.appliedParkingSeasonDifficulty !==
      useUpsert.getField('appliedParkingSeasonDifficulty').value;
    const overnightParkingDifficultyChanged =
      parkingSeasonalityStore.selectedParkingSeasonality.overnightParkingDifficulty !==
      useUpsert.getField('overnightParkingDifficulty').value;

    fieldKey === 'appliedParkingSeasonDifficulty'
      ? isAppliedDifficultyEmpty
        ? appliedDifficultyValueLength
          ? setIsDisable(false)
          : setIsDisable(true)
        : appliedParkingSeasonDifficultyChanged
          ? appliedDifficultyValueLength
            ? setIsDisable(false)
            : setIsDisable(true)
          : setIsDisable(true)
      : fieldKey === 'overnightParkingDifficulty'
        ? isAppliedDifficultyEmpty
          ? appliedDifficultyValueLength
            ? setIsDisable(false)
            : setIsDisable(true)
          : overnightParkingDifficultyChanged
            ? setIsDisable(false)
            : setIsDisable(true)
        : null;
    switch (fieldKey) {
      default:
        break;
    }
    gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      default:
        break;
    }
    return;
  };

  const handleInputChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setFormChanged(true);
    const value = event.target.value;
    vendorLocationStore.searchAirport(value);
  }, 400);

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      default:
        break;
    }
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'AirportReference.Id',
          propertyValue: RootDataStore.locationData.airportReference.id,
        },
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: RootDataStore.locationData.locationId,
          operator: 'and',
        },
      ]),
    };
    parkingSeasonalityStore
      ?.getParkingSeasonality(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: ParkingSeasonalityModel[]) => {
        const data = ParkingSeasonalityModel.deserialize(response.results[0]);
        setAirportAlternate(data.appliedParkingAlternates);
        parkingSeasonalityStore.selectedParkingSeasonality = data;
        setIsDisable(true);
        setFormChanged(false);
        useUpsert.setFormValues(data);
        convertMinutes(data.maximumStayDuration);
        parkingSeasonalityStore.radioValue = data.isMaximumStayAllowed ? 1 : 2;
      });
  };
  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          Airport Parking
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="outlined"
              disabled={!formChanged}
              onClick={() => (formChanged ? getConfirmation() :<></>)}
              size="large"
              hidden={!VendorModuleSecurity.isEditable}
            >
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                parkingSeasonalityStore.radioValue === 1
                  ? totalMins() > 0
                    ? airportAlternate.length > 0
                      ? upsertParkingSeasonality()
                      : AlertStore.info('This Specify Alternate Airport for Parking is required')
                    : AlertStore.info('Maximum allowable parking duration at airport cannot be zero')
                  : parkingSeasonalityStore.radioValue === 2
                    ? upsertParkingSeasonality()
                    : null;
              }}
              size="large"
              disabled={
                isDisable ||
                (parkingSeasonalityStore.radioValue == 1 && parkingError !== '') ||
                useUpsert.getField('appliedParkingSeasonDifficulty').value.length == 0 ||
                ((mins > 9999 || hrs > 999 || days > 9 || weeks > 9) && parkingSeasonalityStore.radioValue == 1)
              }
              hidden={!VendorModuleSecurity.isEditable}
            >
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  };
  const errorHandler = (errors: object): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], '0'));
  };

  const handleErrorResponse = error => {
    if (error?.response?.data?.errors) {
      errorHandler(error.response?.data?.errors);
      return;
    }
    if (error?.message) {
      useUpsert.showAlert(error?.message, '0');
    }
  };
  const handleDropDownChange = (event: any): void => {
    setFormChanged(true);
    parkingSeasonalityStore.selectedParkingSeasonality.appliedParkingSeasonDifficulty.length > 0 ? (
      parkingSeasonalityStore.radioValue == 2 ? (
        setIsDisable(false)
      ) : parkingSeasonalityStore.radioValue == 1 ? (
        event.length == 0 ? (
          (setIsDisable(true), setParkingError('This Specify Alternate Airport for Parking is required'))
        ) : (
          (setIsDisable(true), setParkingError(''))
        )
      ) : (
        <></>
      )
    ) : parkingSeasonalityStore.selectedParkingSeasonality.appliedParkingSeasonDifficulty.length == 0 ? (
      useUpsert.getField('appliedParkingSeasonDifficulty').value.length > 0 ? (
        parkingSeasonalityStore.radioValue == 2 ? (
          setIsDisable(false)
        ) : parkingSeasonalityStore.radioValue == 1 ? (
          event.length == 0 ? (
            (setIsDisable(true), setParkingError('This Specify Alternate Airport for Parking is required'))
          ) : (
            (setIsDisable(false), setParkingError(''))
          )
        ) : (
          <></>
        )
      ) : (
        (setIsDisable(true), setParkingError(''))
      )
    ) : (
      <></>
    );
    parkingSeasonalityStore.radioValue == 1 && event.length == 0 ? (
      (setIsDisable(true), setParkingError('This Specify Alternate Airport for Parking is required'))
    ) : parkingSeasonalityStore.selectedParkingSeasonality.appliedParkingSeasonDifficulty.length > 0 ? (
      (setIsDisable(false), setParkingError(''))
    ) : (
      <></>
    );
    setAirportAlternate(event);
  };
  const upsertParkingSeasonality = (): void => {
    UIStore.setPageLoader(true);
    const request = new ParkingSeasonalityModel({
      ...useUpsert.form.values(),
      isMaximumStayAllowed: parkingSeasonalityStore.radioValue === 1,
      maximumStayDuration: totalMins(),
      appliedParkingAlternates: airportAlternate,
      overnightParkingDifficulty: useUpsert.getField('overnightParkingDifficulty').value
        ? useUpsert.getField('overnightParkingDifficulty').value
        : '',
    });
    parkingSeasonalityStore
      ?.upsertParkingSeasonality(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: (response: ParkingSeasonalityModel) => {
          useUpsert.form.reset();
          setIsDisable(true);
          setAirportAlternate(response.appliedParkingAlternates);
          parkingSeasonalityStore.selectedParkingSeasonality = response;
          useUpsert.setFormValues(response);
          convertMinutes(response.maximumStayDuration);
          parkingSeasonalityStore.radioValue = response.isMaximumStayAllowed ? 1 : 2;
        },
        error: error => {
          handleErrorResponse(error);
        },
      });
  };
  return (
    <Box>
      <ConfirmNavigate isBlocker={false}>
        <DetailsEditorWrapper
          headerActions={headerActions()}
          isEditMode={VendorModuleSecurity.isEditable}
          classes={{ headerActions: classes.headerActions }}
        >
          <div className={classes.editorWrapperContainer}>
            <ViewInputControls
              isEditable={VendorModuleSecurity.isEditable}
              groupInputControls={groupInputControls()}
              onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
              onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
              field={fieldKey => useUpsert.getField(fieldKey)}
              onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
              onFocus={fieldKey => onFocus(fieldKey)}
              classes={{ groupInputControls: classes.groupInputControls }}
            />
            <FormControl>
              <div className={classes.maximumStay}>
                Is there a maximum allowable parking duration (max ground time) at your airport?
              </div>
              <RadioGroup
                className={classes.radio}
                value={parkingSeasonalityStore.radioValue}
                onChange={handleRadioChange}
              >
                <FormControlLabel value={1} control={<Radio />} label="Yes" />
                <FormControlLabel value={2} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            {parkingSeasonalityStore.radioValue === 1 && (
              <div className={classes.textDateTime}>
                <TextField
                  label="MINUTE(S)"
                  error={mins > 9999}
                  helperText={mins > 9999 ? 'Maximum 9999 minutes allowed' : ''}
                  id="mins"
                  value={mins}
                  className={mins > 9999 ? classes.marginTop : ''}
                  onChange={handleChange}
                />
                <TextField
                  label="HOUR(S)"
                  id="hrs"
                  error={hrs > 999}
                  helperText={hrs > 999 ? 'Maximum 999 hours allowed' : ''}
                  className={hrs > 999 ? classes.marginTop : ''}
                  value={hrs}
                  onChange={handleChange}
                />
                <TextField
                  label="DAY(S)"
                  id="days"
                  value={days}
                  error={days > 9}
                  className={days > 9 ? classes.marginTop : ''}
                  helperText={days > 9 ? 'Maximum 9 days allowed' : ''}
                  inputProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  onChange={handleChange}
                />
                <TextField
                  label="WEEK(S)"
                  id="weeks"
                  error={weeks > 9}
                  className={weeks > 9 ? classes.marginTop : ''}
                  helperText={weeks > 9 ? 'Maximum 9 weeks allowed' : ''}
                  value={weeks}
                  onChange={handleChange}
                />
              </div>
            )}
            <div className={classes.flexIcon}>
              {parkingSeasonalityStore.radioValue === 1 ? (
                <>
                  <span>Specify Alternate Airport for Parking</span>
                  <span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                     *
                  </span>
                </>
              ) : (
                <span>Alternate Airport for Parking</span>
              )}
              <Tooltip style={{ fontFamily: 'normal' }} title="Please separate ICAOs with commas" placement="right">
                <InfoIcon className={classes.imageIcon} />
              </Tooltip>
            </div>

            <FormControl variant="outlined">
              <Box>
                <Autocomplete
                  disableCloseOnSelect={true}
                  noOptionsText={
                    parkingSeasonalityStore.getParkingSeasonalitySettingOptions<Airports>(
                      vendorLocationStore.airportList,
                      'airportReference'
                    )?.length === 0
                      ? 'No Options'
                      : 'Type Something to search'
                  }
                  multiple={true}
                  getOptionSelected={(option, value) => option.value === value?.airportReference?.airportId}
                  autoHighlight={true}
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={parkingSeasonalityStore.radioValue === 1 && airportAlternate.length == 0}
                      placeholder={
                        parkingSeasonalityStore.radioValue === 1
                          ? 'Specify Alternate Airport for Parking*'
                          : 'Specify Alternate Airport for Parking'
                      }
                    />
                  )}
                  onChange={(event, value) => handleDropDownChange(value)}
                  onInputChange={handleInputChange}
                  getOptionLabel={option => option.label}
                  value={airportAlternate}
                  options={parkingSeasonalityStore.getParkingSeasonalitySettingOptions<Airports>(
                    vendorLocationStore.airportList,
                    'airportReference'
                  )}
                  className={classes.inputDropdown}
                />
              </Box>
              {parkingError && <FormHelperText style={{ color: '#db063b' }}>{parkingError}</FormHelperText>}
            </FormControl>
          </div>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    </Box>
  );
};

export default inject(
  'settingsStore',
  'vendorLocationStore',
  'parkingSeasonalityStore'
)(withStyles(styles)(observer(Parking)));
