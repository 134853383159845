import React, { FC } from 'react';
import { IClasses } from '@wings-shared/core';
import { observer } from 'mobx-react';
import { Box, Tooltip, Typography, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { styles } from './MainTerminalBothOperationalHours.styles';
import { AirportOperatingHoursAddData, LocationHoursModel } from '../../../../../../Shared';

interface Props {
  classes?: IClasses;
  locationHoursList: LocationHoursModel[];
  handleSave: () => void;
}

const MainTerminalBothOperationalHours: FC<Props> = ({ classes, locationHoursList, handleSave }) => {
  return (
    <Box className={classes.mainOperationalHoursWrapper}>
      <Typography component={'h3'} className={classes.secondaryHeading}>
        Operating Hours
        <Tooltip title="Add the operating hours of each day by clicking on Add Operating Hours" placement="top-start">
          <InfoIcon className={classes?.imageIcon} />
        </Tooltip>
      </Typography>
      <AirportOperatingHoursAddData locationHoursList={locationHoursList} handleSave={handleSave} />
    </Box>
  );
};

export default withStyles(styles)(observer(MainTerminalBothOperationalHours));
