import { auditFields } from '@uplink/shared';

export const fields = {
  ...auditFields,
  id: {
    label: 'Vendor Id',
  },
  appliedParkingSeasonDifficulty:{
    label: 'Select Month with Seasonal Parking Restrictions'
  },
  overnightParkingDifficulty:{
    label: 'Any Issues With Overnight Parking'
  }
}