import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useStyles } from './VendorLocationGeneralInformation.styles';
import { inject, observer } from 'mobx-react';
import { fields } from './Fields';
import {
  IAPISearchFiltersDictionary,
  IClasses,
  IOptionValue,
  UIStore,
  SEARCH_ENTITY_TYPE,
  IAPIGridRequest,
} from '@wings-shared/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { useNavigate, useParams } from 'react-router';
import { DetailsEditorWrapper, ConfirmNavigate } from '@wings-shared/layout';
import { EDITOR_TYPES, IGroupInputControls } from '@wings-shared/form-controls';
import { Box, Button, Typography, withStyles } from '@material-ui/core';
import { MixPanelTrackingEvents, VIEW_MODE, useBaseUpsertComponent } from '@uplink/shared';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { SettingsStore, VendorLocationStore, VendorManagementStore } from '../../../../Stores';
import {
  VendorLocationModel,
  VendorManagmentModel,
  ViewInputControls,
  SidebarMenus,
  CustomTooltip,
} from '../../../Shared';
import { AnalyticsStore } from '@uplink-shared/analytics';
import { AuthStore } from '@uplink-shared/security';

interface Props {
  settingsStore: SettingsStore;
  vendorLocationStore: VendorLocationStore;
  vendorManagementStore: VendorManagementStore;
  params?: { viewMode: VIEW_MODE; id: Number };
  classes?: IClasses;
  searchFilters: IAPISearchFiltersDictionary;
}

const VendorLocationGeneralInformation: FC<Props> = observer(
  ({ settingsStore, vendorLocationStore, vendorManagementStore, searchFilters }) => {
    const unsubscribe = useUnsubscribe();
    const params = useParams();
    const classes = useStyles();
    const useUpsert = useBaseUpsertComponent<VendorLocationModel>(params, fields, searchFilters);
    const [ vendorData, setVendorData ] = useState<VendorManagmentModel>();
    const [ locationLabel, setLocationLabel ] = useState('');
    const formRef = useUpsert.form;
    const navigate = useNavigate();

    const getBasePath = (): string => {
      if (params.id) {
        return `vendor/locations/upsert/${params.id}`;
      }
      return 'vendor/locations/general-information';
    };

    useEffect(() => {
      AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_LOCATION_GENERALINFORMATION);
      loadVendorData();
      SidebarStore.setNavLinks(SidebarMenus(), getBasePath());
      if (RootDataStore.locationData.locationId || params.id) {
        loadInitialData();
      }
    }, [ RootDataStore.locationData.locationId ]);

    const loadVendorData = () => {
      UIStore.setPageLoader(true);
      vendorManagementStore
        ?.getVendorById(AuthStore?.vendorProfile?.id)
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe((response: VendorManagmentModel) => {
          setVendorData(response);
        });
    };

    const loadInitialData = () => {
      UIStore.setPageLoader(true);
      vendorLocationStore
        ?.getVendorLocationById(params.id || RootDataStore.locationData.locationId)
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe((response: VendorLocationModel) => {
          useUpsert.setFormValues(response);
          // setLocationLabel(response.label)
          setLocationLabel('Edit Location');
        });
    };

    const upsertVendorLocation = (): void => {
      const request = new VendorLocationModel({ ...useUpsert.form.values() });
      UIStore.setPageLoader(true);
      vendorLocationStore
        ?.upsertVendorLocation(request.serialize())
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe({
          next: (response: VendorLocationModel) => {
            useUpsert.form.reset();
            useUpsert.setFormValues(response);
            useUpsert.resetFormValidations(response, () => {
              navigate('/vendor/locations/general-information');
              useUpsert.setFormValues(response);
              RootDataStore.setLocation(
                true,
                response.id,
                response.name,
                response.code,
                response.airportReference?.airportId
              );
            });
          },
          error: error => {
            useUpsert.showAlert(error.message, request.id.toString());
          },
        });
    };

    const onValueChange = (value: IOptionValue, fieldKey: string): void => {
      switch (fieldKey) {
        case 'airportReference':
          const request: IAPIGridRequest = {
            filterCollection: JSON.stringify([
              {
                propertyName: 'AirportLocation.Country.CountryId',
                propertyValue: vendorData?.vendorAddress.countryReference.countryId,
              },
            ]),
          };
          vendorLocationStore.getVmsIcaoCode(request).subscribe();
          break;
        default:
          break;
      }
      useUpsert.getField(fieldKey).set(value);
    };

    const onSearch = (searchValue: string, fieldKey: string): void => {
      switch (fieldKey) {
        case 'airportReference':
          vendorLocationStore.searchAirport(searchValue);
          break;
        default:
          break;
      }
      return;
    };

    const groupInputControls = (): IGroupInputControls[] => {
      return [
        {
          title: 'General Information:',
          inputControls: [
            {
              fieldKey: 'id',
              type: EDITOR_TYPES.TEXT_FIELD,
              isHidden: true,
            },
            {
              fieldKey: 'vendor',
              type: EDITOR_TYPES.DROPDOWN,
              isHidden: true,
            },
            {
              fieldKey: 'name',
              type: EDITOR_TYPES.TEXT_FIELD,
            },
            {
              fieldKey: 'code',
              type: EDITOR_TYPES.TEXT_FIELD,
              isHidden: true,
            },
            {
              fieldKey: 'locationLegalName',
              type: EDITOR_TYPES.TEXT_FIELD,
            },
            {
              fieldKey: 'airportReference',
              type: EDITOR_TYPES.DROPDOWN,
              options: vendorLocationStore.airportList,
              searchEntityType: SEARCH_ENTITY_TYPE.AIRPORT,
              isDisabled: Boolean(RootDataStore.locationData.locationId || params.id),
            },
            {
              fieldKey: 'vendorLocationStatus',
              type: EDITOR_TYPES.DROPDOWN,
              options: settingsStore.vendorLocationSettings,
              isHidden: true,
            },
            {
              fieldKey: 'rankAtAirport',
              type: EDITOR_TYPES.TEXT_FIELD,
              isHidden: true,
            },
            {
              fieldKey: 'locationStatusDetails',
              type: EDITOR_TYPES.TEXT_FIELD,
              isHalfFlex: true,
              isHidden: true,
            },
            {
              fieldKey: 'countryDataManagement',
              type: EDITOR_TYPES.CHECKBOX,
              isDisabled: true,
              isHidden: !Boolean(RootDataStore.locationData.locationId),
            },
            {
              fieldKey: 'permitDataManagement',
              type: EDITOR_TYPES.CHECKBOX,
              isDisabled: true,
              isHidden: !Boolean(RootDataStore.locationData.locationId),
            },
            {
              fieldKey: 'airportDataManagement',
              type: EDITOR_TYPES.CHECKBOX,
              isDisabled: true,
              isHidden: !Boolean(RootDataStore.locationData.locationId),
            },
          ],
        },
      ];
    };

    const title = (): string => {
      return params.id || RootDataStore.locationData.locationId ? (
        <CustomTooltip title={`${locationLabel}`} />
      ) : (
        'Add Location'
      );
    };

    const headerActions = (): ReactNode => {
      return (
        <>
          <Typography variant="h5">{title()}</Typography>
          <Box sx={{ display: 'flex' }}>
            <div className={`${classes.defaultButton}`}>
              <Button color="primary" variant="outlined" onClick={() => navigate('/vendor/locations')} size="large">
                Cancel
              </Button>
            </div>
            <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => upsertVendorLocation()}
                size="large"
                disabled={!formRef.changed || !formRef.isValid || formRef.hasError}
              >
                Save
              </Button>
            </div>
          </Box>
        </>
      );
    };

    const onFocus = (fieldKey: string): void => {
      switch (fieldKey) {
        case 'airportReference':
          const request: IAPIGridRequest = {
            filterCollection: JSON.stringify([
              {
                propertyName: 'AirportLocation.Country.CountryId',
                propertyValue: vendorData?.vendorAddress.countryReference.countryId,
              },
            ]),
          };
          vendorLocationStore.getVmsIcaoCode(request).subscribe();
          break;
        default:
          break;
      }
    };

    return (
      <ConfirmNavigate isBlocker={formRef.changed}>
        <DetailsEditorWrapper
          headerActions={headerActions()}
          isEditMode={true}
          classes={{ headerActions: classes.headerActions }}
        >
          <div className={classes.editorWrapperContainer}>
            <ViewInputControls
              isEditable={true}
              groupInputControls={groupInputControls()}
              onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
              onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
              field={fieldKey => useUpsert.getField(fieldKey)}
              onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
              onFocus={fieldKey => onFocus(fieldKey)}
            />
          </div>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    );
  }
);
export default inject(
  'settingsStore',
  'vendorLocationStore',
  'vendorManagementStore'
)(VendorLocationGeneralInformation);
