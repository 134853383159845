import { IBaseModuleProps } from '@uplink/shared';
import { inject } from 'mobx-react';
import React, { Component, ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CoreModule, DataExpirationWorkflow } from './Modules';
import {
  AuthStore,
  MODULE_ACTIONS,
  ModulePermissions,
  SettingsModuleSecurity,
  USER_GROUP,
  UserModel,
} from '@uplink-shared/security';
import { NotFoundPage } from '@wings-shared/core';
import { SidebarMenus, VendorModuleSecurity } from './Modules/Shared';
import VendorAddress from './Modules/VendorAddress/VendorAddress';
import UpsertAddress from './Modules/VendorAddress/Components/AddAddress/UpsertAddress';
import VendorDocuments from './Modules/VendorDocuments/VendorDocuments';
import VendorLocations from './Modules/VendorLocations/VendorLocations';
import VendorContact from './Modules/VendorContact/VendorContact';
import UpsertContact from './Modules/VendorContact/Components/AddContact/UpsertContact';
import UpsertDocument from './Modules/VendorDocuments/Components/UpsertDocument';
import VendorLocationAddress from './Modules/VendorLocations/Components/VendorLocationAddress/VendorLocationAddress';
import UpsertVendorLocationAddress from 
  './Modules/VendorLocations/Components/VendorLocationAddress/Components/AddAddress/UpsertVendorLocationAddress';
import VendorLocationGeneralInformation from 
  './Modules/VendorLocations/Components/VendorLocationGeneralInformation/VendorLocationGeneralInformation';
import LocationContacts from './Modules/VendorLocations/Components/LocationContacts/LocationContacts';
import UpsertLocationContact from 
  './Modules/VendorLocations/Components/LocationContacts/Components/UpsertLocationContact';
import LocationDocuments from './Modules/VendorLocations/Components/LocationDocument/LocationDocuments';
import UpsertLocationDocument from 
  './Modules/VendorLocations/Components/LocationDocument/Components/UpsertLocationDocument';
import OperationalEssentials from 
  './Modules/VendorLocations/Components/OperationalEssentials/OperationalEssentials';
import VendorLocationPricing from 
  './Modules/VendorLocations/Components/VendorLocationPricing/VendorLocationPricing';
import VendorUser from './Modules/Vendor/VendorUser/VendorUser';
import UpsertUser from './Modules/Vendor/VendorUser/Components/UpsertUser';
import AircraftLogistics from 
  './Modules/OperationalInformation/Handler/AircraftLogistics/AircraftLogistics';
import PassengerLogistics from 
  './Modules/OperationalInformation/Handler/PassengerLogistics/PassengerLogistics';
import OperationalHours from './Modules/OperationalInformation/Handler/OperationalHours/OperationalHours';
import OperationalInformation from 
  './Modules/VendorLocations/Components/OperationalInformation/OperationalInformation';
import VendorLocationOnBoarding from './Modules/VendorLocationOnBoarding/VendorLocationOnBoarding';
import AirportHours from './Modules/OperationalInformation/Airport/AirportHours/AirportHours';
import CustomNotes from './Modules/OperationalInformation/Airport/CustomNotes/CustomNotes';
import { ProtectedRoute, SidebarStore } from '@uplink-shared/layout';
import UpsertSlide3 from './Modules/VendorLocationOnBoarding/Components/Slide3/Components/UpsertSlide3';
import GroundServiceEquipment from 
  './Modules/OperationalInformation/Handler/GroundServiceEquipment/GroundServiceEquipment';
import ExpiryPricing from './Modules/DataExpirationWorkflow/Components/ExpiryPricing/ExpiryPricing';
import ExpiryDocument from './Modules/DataExpirationWorkflow/Components/ExpiryDocument/ExpiryDocument';
import ExpiryAddress from './Modules/DataExpirationWorkflow/Components/ExpiryAddress/ExpiryAddress';
import ExpiryContact from './Modules/DataExpirationWorkflow/Components/ExpiryContact/ExpiryContact';
import { ModeStore } from '@wings-shared/mode-store';
import Events from './Modules/OperationalInformation/Handler/Events/Events';
import UpsertEvents from './Modules/OperationalInformation/Handler/Events/Components/UpsertEvents';
import Parking from './Modules/OperationalInformation/Airport/Parking/Parking';
import HealthRequirements from './Modules/OperationalInformation/Country/HealthRequirements/HealthRequirements';
import Cabotage from './Modules/OperationalInformation/Country/Cabotage/Cabotage';
import HandlerNotes from './Modules/OperationalInformation/Handler/Notes/HandlerNotes';
import CountryNotes from './Modules/OperationalInformation/Country/Notes/CountryNotes';
import AirportNotes from './Modules/OperationalInformation/Airport/Notes/AirportNotes';
import UpsertHandlerNotes from './Modules/OperationalInformation/Handler/Notes/Components/UpsertHandlerNotes';
import UpsertCountryNotes from './Modules/OperationalInformation/Country/Notes/Components/UpsertCountryNotes';
import UpsertAirportNotes from './Modules/OperationalInformation/Airport/Notes/Components/UpsertAirportNotes';
import Permissions from './Modules/OperationalInformation/Airport/Permissions/Permissions';
import VendorList from './Modules/VendorList/VendorList';

const permissions: ModulePermissions = {
  [MODULE_ACTIONS.EDIT]: [ USER_GROUP.CORPORATE_AUTHORIZED_AGENT ],
};

@inject('sidebarStore')
class VendorApp extends Component<IBaseModuleProps> {
  redirectPath = '/vendor';
  constructor(props) {
    super(props);
    this.props.sidebarStore.setNavLinks(SidebarMenus, this.props.basePath);
    VendorModuleSecurity.init();
    SettingsModuleSecurity.updatePermissions(permissions);
    AuthStore.configureAgGrid();
    SidebarStore.setNavLinks([], '/vendor');
  }

  private hasPermissionForVendor(): boolean {
    return AuthStore.user.isCorporateAuthorizedAgent;
  }

  public render(): ReactNode {
    return (
      <Routes basename={this.props.basePath}>
        <Route path="vendor/*">
          <Route index element={<DataExpirationWorkflow />} />
          <Route path="vendors/*" element={<VendorList />} />
          <Route index element={<Navigate to="general-info" replace />} />
          <Route path="general-info" element={<CoreModule />} />
          <Route path="addresses/*" element={<VendorAddress />} />
          <Route
            path="addresses/:upsert/*"
            element={
              <ProtectedRoute
                hasPermission={VendorModuleSecurity.isEditable}
                redirectPath={this.redirectPath}
                element={<UpsertAddress />}
              />
            }
            key="vendor-address-add"
          />
          <Route
            path="addresses/:upsert/:id/*"
            element={
              <ProtectedRoute
                hasPermission={VendorModuleSecurity.isEditable}
                redirectPath={this.redirectPath}
                element={<UpsertAddress />}
              />
            }
            key="vendor-address-update"
          />
          <Route path="contact/*" element={<VendorContact />} />
          <Route
            path="contact/:upsert/*"
            element={
              <ProtectedRoute
                hasPermission={VendorModuleSecurity.isEditable}
                redirectPath={this.redirectPath}
                element={<UpsertContact />}
              />
            }
            key="vendor-contact-add"
          />
          <Route
            path="contact/:upsert/:id/:vendorContactId/*"
            element={
              <ProtectedRoute
                hasPermission={VendorModuleSecurity.isEditable}
                redirectPath={this.redirectPath}
                element={<UpsertContact />}
              />
            }
            key="vendor-conatact-update"
          />
          <Route path="documents/*" element={<VendorDocuments />} />
          <Route
            path="documents/:upsert/:id"
            element={
              <ProtectedRoute
                hasPermission={VendorModuleSecurity.isEditable}
                redirectPath={this.redirectPath}
                element={<UpsertDocument />}
              />
            }
          />
          <Route
            path="documents/:upsert/*"
            element={
              <ProtectedRoute
                hasPermission={VendorModuleSecurity.isEditable}
                redirectPath={this.redirectPath}
                element={<UpsertDocument />}
              />
            }
          />

          <Route path="user/*" element={<VendorUser />} />
          <Route path="user/:upsert/*" element={<UpsertUser />} key="vendor-user-add" />
          <Route path="user/:upsert/:id/*" element={<UpsertUser />} key="vendor-user-update" />

          <Route path="locations/*" element={<VendorLocations />} />
          <Route path="locations/:upsert/*" element={<VendorLocationGeneralInformation />} key="vendor-location-add" />
          <Route
            path="locations/:upsert/:id/*"
            element={<VendorLocationGeneralInformation />}
            key="vendor-location-update"
          />
          <Route path="location/addresses/*" element={<VendorLocationAddress />} />
          <Route
            path="location/addresses/:upsert/:id"
            element={<UpsertVendorLocationAddress />}
            key="vendor-location-address-update"
          />
          <Route
            path="location/addresses/:upsert/*"
            element={<UpsertVendorLocationAddress />}
            key="vendor-location-address-update"
          />

          <Route path="location/contact/*" element={<LocationContacts />} key="vendor-location-contacts" />
          <Route
            path="location/contact/:upsert/:id/:contactId/*"
            element={<UpsertLocationContact />}
            key="vendor-location-contact-update"
          />
          <Route
            path="location/contact/:upsert/*"
            element={<UpsertLocationContact />}
            key="vendor-location-contact-add"
          />
          <Route
            path="location/operational-essential/*"
            element={<OperationalEssentials />}
            key="vendor-location-contacts"
          />
          <Route
            path="location/operational-information/*"
            element={<OperationalInformation />}
            key="vendor-location-operational-information"
          />

          <Route path="location/documents/*" element={<LocationDocuments />} key="vendor-location-documents" />
          <Route
            path="location/documents/:upsert/:id"
            element={<UpsertLocationDocument />}
            key="vendor-location-documents-update"
          />
          <Route
            path="location/documents/:upsert/*"
            element={<UpsertLocationDocument />}
            key="vendor-location-documents-add"
          />
          <Route path="location/pricing/*" element={<VendorLocationPricing />} key="vendor-location-pricing" />

          <Route
            path="location/operational-information/operational-hours/*"
            element={<OperationalHours />}
            key="vendor-location-hours"
          />
          <Route
            path="location/operational-information/airport-hours/*"
            element={<AirportHours />}
            key="vendor-location-airport-hours"
          />
          <Route
            path="location/operational-information/aircraft-logistics/*"
            element={<AircraftLogistics />}
            key="vendor-location-aircraft"
          />
          <Route
            path="location/operational-information/passenger-logistics/*"
            element={<PassengerLogistics />}
            key="vendor-location-passenger"
          />

          <Route
            path="location/operational-information/ground-service-equipment/*"
            element={<GroundServiceEquipment />}
            key="ground-service-equipment"
          />

          <Route
            path="location/airport/parking/*"
            element={<Parking />}
            key="parking-seasonality"
          />

          <Route
            path="new-vendor-information/*"
            element={<VendorLocationOnBoarding />}
            key="vendor-location-onboarding"
          />

          <Route
            path="new-vendor-information/slide3/:upsert/:id/:operationTypeId/:documentId/*"
            element={<UpsertSlide3 />}
            key="vendor-location-onboarding-slide3"
          />

          <Route path="location/airport/custom-notes/*" element={<CustomNotes />} key="custom-notes" />

          <Route path="location/handler/notes/*" element={<HandlerNotes />} key="handler-bulletins" />
          <Route path="location/handler/notes/:upsert/:dataSource/*" 
            element={<UpsertHandlerNotes />} key="handler-bulletins-add" />
          <Route
            path="location/handler/notes/:upsert/:id/:dataSource/"
            element={<UpsertHandlerNotes />}
            key="airport-bulletins-update"
          />

          <Route path="location/country/notes/*" element={<CountryNotes />} key="country-bulletins" />
          <Route path="location/country/notes/:upsert/:dataSource/*" 
            element={<UpsertCountryNotes />} key="country-bulletins-add" />
          <Route
            path="location/country/notes/:upsert/:id/:dataSource/"
            element={<UpsertCountryNotes />}
            key="country-bulletins-update"
          />
          <Route path="location/airport/notes/*" element={<AirportNotes />} key="airport-bulletins" />

          <Route path="location/airport/notes/:upsert/:dataSource/*" 
            element={<UpsertAirportNotes />} key="airport-bulletins-add" />
          <Route
            path="location/airport/notes/:upsert/:id/:dataSource/"
            element={<UpsertAirportNotes />}
            key="airport-bulletins-update"
          />

          <Route path="location/airport/permissions/*" element={<Permissions />} key="ppr-notes" />

          <Route
            path="location/country/health-requirements/*"
            element={<HealthRequirements />}
            key="health-requirements"
          />
          <Route path="location/country/cabotage/*" element={<Cabotage />} key="cabotage" />

          {/* EXPIRY ROUTES */}

          <Route path="location/expiry/pricing/*" element={<ExpiryPricing />} key="expiry-pricings" />

          <Route path="location/expiry/documents/*" element={<ExpiryDocument />} key="expiry-documents" />

          <Route path="location/expiry/contacts/*" element={<ExpiryContact />} key="expiry-contacts" />

          <Route path="location/expiry/addresses/*" element={<ExpiryAddress />} key="expiry-addresses" />

          {/* EXPIRY UPSERT ROUTES */}

          <Route path="location/expiry/documents/upsert" element={<UpsertLocationDocument />} key="expiry-documents" />

          <Route
            path="location/expiry/addresses/upsert"
            element={<UpsertVendorLocationAddress />}
            key="expiry-addresses"
          />

          <Route path="location/expiry/contacts/upsert" element={<UpsertLocationContact />} key="expiry-contacts" />

          {/* EXPIRY EDIT ROUTES */}

          <Route
            path="location/expiry/documents/upsert/:id"
            element={
              <ProtectedRoute
                hasPermission={VendorModuleSecurity.isEditable}
                redirectPath={this.redirectPath}
                element={<UpsertLocationDocument />}
              />
            }
          />

          <Route
            path="location/expiry/addresses/upsert/:id"
            element={
              <ProtectedRoute
                hasPermission={VendorModuleSecurity.isEditable}
                redirectPath={this.redirectPath}
                element={<UpsertVendorLocationAddress />}
              />
            }
            key="expiry-addresses"
          />

          <Route
            path="location/expiry/contacts/upsert/:id/:contactId"
            element={
              <ProtectedRoute
                hasPermission={VendorModuleSecurity.isEditable}
                redirectPath={this.redirectPath}
                element={<UpsertLocationContact />}
              />
            }
            key="expiry-contacts"
          />

          <Route path="location/:type/events/*" element={<Events />} key="handler-events" />
          <Route path="location/:type/events/:upsert/*" element={<UpsertEvents />} key="handler-events-add" />
          <Route path="location/:type/events/:upsert/:id/:dataSource" 
            element={<UpsertEvents />} key="handler-events-update" />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    );
  }
}
export default VendorApp;
