import React, { FC, ReactNode, useEffect, useState } from 'react';
import { MixPanelTrackingEvents, useBaseUpsertComponent } from '@uplink/shared';
import { SettingsStore, ContactMasterStore } from '../../../../Stores';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useGridState } from '@wings-shared/custom-ag-grid';
import { useStyles } from './UpsertContact.styles';
import { inject, observer } from 'mobx-react';
import { fields } from './Fields';
import {
  IAPISearchFiltersDictionary,
  IClasses,
  UIStore,
  IAPIGridRequest,
  Utilities,
  IAPIPageResponse,
  regex,
} from '@wings-shared/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { useNavigate, useParams } from 'react-router';
import { DetailsEditorWrapper, ConfirmNavigate, SidebarStore, ConfirmDialog } from '@uplink-shared/layout';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { SettingNamesMapper } from '../../../../Stores/SettingsMapper';
import {
  COLLECTION_NAMES,
  SidebarMenus,
  SETTING_ID,
  VendorContactModel,
  ContactMasterModel,
  SettingBaseModel,
  ViewInputControls,
} from '../../../Shared';
import { Box, Button, Typography } from '@material-ui/core';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { IAPIResponseVendorContact } from '../../../Shared/Interfaces/Response/API-Response-VendorContact';
import { AnalyticsStore } from '@uplink-shared/analytics';

interface Props {
  settingsStore: SettingsStore;
  contactMasterStore: ContactMasterStore;
  params?: { id: Number };
  classes?: IClasses;
  searchFilters: IAPISearchFiltersDictionary;
}

const UpsertContact: FC<Props> = observer(({ settingsStore, searchFilters, contactMasterStore }) => {
  const classes = useStyles();
  const unsubscribe = useUnsubscribe();
  const params = useParams();
  const gridState = useGridState();
  const useUpsert = useBaseUpsertComponent<ContactMasterModel>(params, fields, searchFilters);

  const formRef = useUpsert.form;
  const navigate = useNavigate();
  const [ selectedVendorContact, setSelectedVendorContact ] = useState(new VendorContactModel());

  const getBasePath = (): string => {
    if (params.id) {
      return `vendor/contact/upsert/${params.id}/`;
    }
    return 'vendor/contact/upsert/';
  };

  useEffect(() => {
    contactMasterStore.isPhoneNumberValid = true;
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_CONTACT);
    loadInitialData();
    loadVendorContactData();
    SidebarStore.setNavLinks(SidebarMenus(), getBasePath());
  }, []);

  const isEditable = useUpsert.isEditable;

  const loadInitialData = (pageRequest?: IAPIGridRequest) => {
    if (params.id) {
      const request: IAPIGridRequest = {
        pageNumber: gridState.pagination.pageNumber,
        pageSize: 500,
        ...pageRequest,
      };
      settingsStore.getSettings(SETTING_ID.SETTING_CONTACT_METHOD, request).subscribe();
      UIStore.setPageLoader(true);
      contactMasterStore
        ?.getMasterContactById(params.id)
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe((response: IAPIPageResponse<IAPIResponseVendorContact>) => {
          if (response) {
            setFormValues(response);
            validateContact(response?.contactMethod);
          }
        });
    }
  };
  const getConfirmation = (): void => {
    if (formRef.changed) {
      ModalStore.open(
        <ConfirmDialog
          title="Confirm Changes"
          message={'Canceling will lost your changes. Are you sure you want to cancel?'}
          yesButton="Confirm"
          onNoClick={() => ModalStore.close()}
          onYesClick={() => {
            useUpsert.form.reset();
            loadInitialData();
            ModalStore.close();
          }}
        />
      );
    }
  };
  const loadVendorContactData = (pageRequest?: IAPIGridRequest) => {
    if (params.vendorContactId) {
      UIStore.setPageLoader(true);
      contactMasterStore
        ?.getVendorContactById(params.vendorContactId)
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe((response: IAPIPageResponse<IAPIResponseVendorContact>) => {
          if (response) {
            useUpsert.getField('contactUsegeType').set(response?.contactUsegeType);
            if (response?.contact?.contactMethod?.id === 1) {
              const regex = /^\+?[0-9]{1,3}([-.\s]?\d{1,4}){1,5}$/;
              const phoneNumber = response?.contact?.contact;
              if (regex.test(phoneNumber)) {
                contactMasterStore.isPhoneNumberValid = true;
              } else {
                contactMasterStore.isPhoneNumberValid = false;
              }
            }
          }
        });
    }
  };

  const setFormValues = response => {
    useUpsert.setFormValues(response);
    const vendorContact = ContactMasterModel.deserialize(response);
    useUpsert.getField('id').set(vendorContact.id);
    if (response?.contactMethod?.id === 1) {
      const regex = /^\+?[0-9]{1,3}([-.\s]?\d{1,4}){1,5}$/;
      const phoneNumber = response?.contact;
      const isValid = regex.test(phoneNumber);
      if (isValid) {
        contactMasterStore.isPhoneNumberValid = true;
        contactMasterStore.phoneNumberValidationMessage = '';
        if (phoneNumber?.length > 20 || phoneNumber?.length < 7) {
          contactMasterStore.phoneNumberValidationMessage = 'The Contact* field must be between 7 and 20 characters.';
        }
      } else {
        contactMasterStore.isPhoneNumberValid = false;
        contactMasterStore.phoneNumberValidationMessage = 'Please use "+" , "-" and digits only.';
        if (phoneNumber?.length > 20) {
          contactMasterStore.phoneNumberValidationMessage = 'The Contact* field must be between 7 and 20 characters.';
        }
      }
    }
    loadVendorContactData();
  };

  const formatWebsiteUrl = (input: string) => {
    if (input.startsWith('https://')) {
      return input;
    } else {
      return `https://${input}`;
    }
  };

  const upsertVendorContact = (): void => {
    UIStore.setPageLoader(true);
    const request = new ContactMasterModel({ ...useUpsert.form.values() });
    const websiteUrl = request.contactMethod.id === 7 ? formatWebsiteUrl(request.contact) : request.contact;
    request.contact = websiteUrl;
    contactMasterStore
      ?.upsertMasterContact(request.serialize(parseInt(params.id)))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: ContactMasterModel) => {
          const vendorContactRequest = selectedVendorContact;
          vendorContactRequest.contact = response;
          contactMasterStore
            ?.upsertVendorContact(
              vendorContactRequest.serialize(params.vendorContactId, response.id, 0, request.contactUsegeType.id),
              0
            )
            .pipe(
              takeUntil(unsubscribe.destroy$),
              finalize(() => UIStore.setPageLoader(false))
            )
            .subscribe({
              next: (response: VendorContactModel) => {
                useUpsert.form.reset();
                setFormValues(selectedVendorContact.contact);
                useUpsert.getField('contactUsegeType').set(response.contactUsegeType);
                useUpsert.resetFormValidations(selectedVendorContact.contact, () => {
                  navigate('/vendor/contact');
                  contactMasterStore.hasDataLoaded = true;
                });
              },
              error: error => {
                useUpsert.showAlert(error.message, vendorContactRequest.id.toString());
              },
            });
        },
        error: error => {
          useUpsert.showAlert(error.message, request.id.toString());
        },
      });
  };

  const onValueChange = (value: SettingBaseModel, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    contactMasterStore.isPhoneNumberValid = true;
    switch (fieldKey) {
      case 'contactMethod':
        const contactFiled = useUpsert.getField('contact');
        contactFiled.value = '';
        validateContact(value);
        break;
      case 'contact':
        if (!value) {
          contactMasterStore.phoneNumberValidationMessage = 'The Contact* field is required.';
          break;
        }
        const contactMethod = useUpsert.getField('contactMethod')?.value;
        if (contactMethod?.id === 1) {
          const regex = /^\+?[0-9]{1,3}([-.\s]?\d{1,4}){1,5}$/;
          const isValid = regex.test(value);
          if (isValid) {
            contactMasterStore.isPhoneNumberValid = true;
            contactMasterStore.phoneNumberValidationMessage = '';
            if (value?.length > 20 || value?.length < 7) {
              contactMasterStore.phoneNumberValidationMessage =
                'The Contact* field must be between 7 and 20 characters.';
            }
          } else {
            contactMasterStore.isPhoneNumberValid = false;
            contactMasterStore.phoneNumberValidationMessage = 'Please use "+" , "-" and digits only.';
            if (value?.length > 20) {
              contactMasterStore.phoneNumberValidationMessage =
                'The Contact* field must be between 7 and 20 characters.';
            }
          }
        }
        break;
      default:
        break;
    }
    gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
  };

  const validateContact = (value: SettingBaseModel) => {
    const contactFiled = useUpsert.getField('contact');
    contactFiled.$placeholder = setPlaceHolder(value);
    contactFiled.$rules = getContactRule(value);
    gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi));
  };

  const setPlaceHolder = value => {
    if (!value) {
      return '';
    }
    switch (value.id) {
      case 2:
        return 'Please enter Fax Number';
      case 1:
        return '+xx-xxx-xxxxxxxx';
      case 7:
        return 'Please enter Website URL';
      case 3:
        return 'Please enter email';
      case 5:
      case 4:
      case 6:
        return 'Please enter Alpha Numeric';
      default:
        return '';
    }
  };

  const getContactRule = (value: SettingBaseModel): string => {
    if (!value) {
      return 'required';
    }
    switch (value.id) {
      case 2:
      case 1:
        return 'required|between:7,20|regex:/^\\+?[0-9]{1,3}([-.\\s]?\\d{1,4}){1,5}$/';
      case 7:
        return 'required|string|between:3,320|regex:/^[^\\s]+\\.[^\\s]+$/';
      case 3:
        return `required|regex:${regex.email}`;
      case 5:
      case 4:
        return `required|between:6,7|regex:${regex.alphaNumericWithoutSpaces}`;
      case 6:
        return `required|between:6,8|regex:${regex.alphaNumericWithoutSpaces}`;
      default:
        return 'required';
    }
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'contactName',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'title',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'contactUsegeType',
            type: EDITOR_TYPES.DROPDOWN,
            options: settingsStore.vendorContactUsageType,
          },
          {
            fieldKey: 'contactType',
            type: EDITOR_TYPES.DROPDOWN,
            options: settingsStore.vendorContactType,
          },
          {
            fieldKey: 'contactMethod',
            type: EDITOR_TYPES.DROPDOWN,
            options: settingsStore.vendorContactMethod,
          },
          {
            fieldKey: 'contact',
            type: EDITOR_TYPES.TEXT_FIELD,
            customErrorMessage:
              !contactMasterStore.isPhoneNumberValid && contactMasterStore.phoneNumberValidationMessage,
          },
          {
            fieldKey: 'isSMSCompatible',
            type: EDITOR_TYPES.CHECKBOX,
          },
        ],
      },
    ];
  };

  const dialogHeader = (): ReactNode => {
    return params.id ? 'Edit Contact' : 'Add New Contact';
  };

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5">{dialogHeader()}</Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button color="primary" variant="outlined" onClick={() => navigate('/vendor/contact')} size="large">
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => upsertVendorContact()}
              size="large"
              disabled={!formRef.changed || !formRef.isValid || formRef.hasError}
            >
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case 'contactMethod':
        settingsStore.getSettings(SETTING_ID.SETTING_CONTACT_METHOD).subscribe();
        break;
      case 'contactType':
        settingsStore.getSettings(SETTING_ID.SETTING_CONTACT_TYPE).subscribe();
        break;
      case 'contactUsegeType':
        settingsStore.getSettings(SETTING_ID.SETTING_USAGES_TYPE).subscribe();
        break;
      default:
        break;
    }
  };

  return (
    <ConfirmNavigate isBlocker={formRef.changed}>
      <DetailsEditorWrapper
        headerActions={headerActions()}
        isEditMode={isEditable}
        classes={{ headerActions: classes.headerActions }}
      >
        <div className={classes.editorWrapperContainer}>
          <ViewInputControls
            isEditable={true}
            groupInputControls={groupInputControls()}
            onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
            onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
            field={fieldKey => useUpsert.getField(fieldKey)}
            onFocus={fieldKey => onFocus(fieldKey)}
          />
        </div>
      </DetailsEditorWrapper>
    </ConfirmNavigate>
  );
});
export default inject('settingsStore', 'contactMasterStore')(UpsertContact);
