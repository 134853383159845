import { HttpClient, baseApiPath } from '@uplink/shared';
import { observable, action } from 'mobx';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { Logger } from '@wings-shared/security';
import { IAPIGridRequest, IAPIPageResponse, tapWithAction, Utilities } from '@wings-shared/core';
import { refDataHeaders, vendorManagementHeaders } from './Base.store';
import { apiUrls } from './API.url';
import { AoeDesignationModel } from '../Modules/Shared/Models/AoeDesignationModel.model';
import { Airports, SettingBaseModel } from '../Modules/Shared';
import { AoeDesignationOptionModel } from '../Modules/Shared/Models/AoeDesignationOptionModel.model';

export class AoeDesignationStore {
  @observable public formValueChanged: boolean = false;
  @observable public airportCustomReferenceList: AoeDesignationModel[] = [];
  @observable public airportEntryData: number = 2;
  @observable public customsAvailableData: number = 1;
  @observable public customsForPassenger: number = 1;
  @observable public customsClearance: number = 1;
  @observable public generalAviationClearance: number = 1;
  @observable public generalAviationClearanceNumber: string = '';
  @observable public clearanceLocationSpecificsData: string = '';

  @observable public disableSaveButton: boolean = true;
  @observable public isValueChanged: boolean = false;

  @observable public aoeDesignationData: AoeDesignationModel = new AoeDesignationModel();
  @observable public isAoeDesignationValueChange: boolean = false;

  @observable public customsOfficersAirportList: SettingBaseModel[] = [];
  @observable public passengersExceedLimitListValue: SettingBaseModel[] = [];
  @observable public customsForPassengerData: AoeDesignationOptionModel[] = [];
  @observable public appliedMaxPOBAltClearanceOptions: AoeDesignationOptionModel = null;
  @observable public customsOfficersDutyValue: Airports = null;
  @observable public customsPassengerOptions: AoeDesignationModel[] = [];

  @action
  public getAOESettingOptions<T>(models: T[], settingName: string) {
    const settingOptions: AoeDesignationOptionModel[] = [];
    models.forEach(model => {
      const settingOption: AoeDesignationOptionModel = new AoeDesignationOptionModel();
      settingOption.id = 0;
      settingOption[settingName] = model;
      settingOptions.push(settingOption);
    });
    return settingOptions;
  }

  @action
  public getAoeDesignation(pageRequest?: IAPIGridRequest): Observable<IAPIPageResponse<AoeDesignationModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: 'AirportCustomReference',
      ...pageRequest,
    });

    return http.get<IAPIPageResponse<AoeDesignationModel>>(`/${apiUrls.vendorManagement}?${params}`).pipe(
      Logger.observableCatchError,

      map(response => {
        this.airportCustomReferenceList = AoeDesignationModel.deserializeList(response.results);
        return { ...response, results: this.airportCustomReferenceList };
      })
    );
  }

  @action
  public upsertAoeDesignation(payload: AoeDesignationModel): Observable<AoeDesignationModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !(payload.id != null && payload.id !== 0);
    const upsertRequest: Observable<AoeDesignationModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.airportCustomReference}`, payload)
      : http.put<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.airportCustomReference}/${payload.id}`,
        payload
      );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() => AlertStore.info('Data saved successfully!')),
      map(response => AoeDesignationModel.deserialize(response))
    );
  }
}
