import React, { FC, useEffect, useState } from 'react';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useBaseUpsertComponent } from '@uplink/shared';
import {
  IClasses,
  Utilities,
  IOptionValue,
  UIStore,
  IAPISearchFiltersDictionary,
  IAPIGridRequest,
  IAPIPageResponse,
  regex,
} from '@wings-shared/core';
import { ConfirmNavigate, RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { FormControlLabel, Switch, Tooltip, Typography, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import {
  BaseStore,
  SlideEightStore,
  SlideOneAndTwoStore,
  SlideSevenStore,
  VendorLocationStore,
  VendorManagementStore,
} from '../../../../Stores';
import {
  LocationHoursModel,
  newVendorOnboarding,
  OperatingHoursAddData,
  OperationalEssentialsModel,
  VendorLocationContactModel,
  VendorLocationModel,
  VendorOnBoardSlideEightModel,
  VendorOnBoardSlideSevenModel,
  ViewInputControls,
} from '../../../Shared';
import { finalize, takeUntil, catchError, mergeMap } from 'rxjs/operators';
import { AuthStore } from '@uplink-shared/security';
import InfoIcon from '@material-ui/icons/Info';
import { fields } from './Fields';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { forkJoin, of } from 'rxjs';
import { styles } from './Slide8.styles';

interface Props {
  classes?: IClasses;
  slideEightStore: SlideEightStore;
  slideOneAndTwoStore: SlideOneAndTwoStore;
  vendorLocationStore: VendorLocationStore;
  searchFilters: IAPISearchFiltersDictionary;
  registerSaveData: (saveData: () => void) => void;
  activeStep: number;
  setActiveStep: React.Dispatch<number>;
  onNextButtonDisable: (boolean) => void;
}

const Slide7: FC<Props> = ({
  classes,
  searchFilters,
  vendorLocationStore,
  registerSaveData,
  activeStep,
  setActiveStep,
  slideEightStore,
  onNextButtonDisable,
  slideOneAndTwoStore,
}) => {
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();
  const [ locations, setLocations ] = useState([]);
  const useUpsert = useBaseUpsertComponent<VendorOnBoardSlideEightModel>(
    {},
    fields,
    searchFilters
  );
  const formRef = useUpsert.form;

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  const saveData = () => {
    upsertSlideEight();
  };

  const validateForm = () => {
    return !formRef.isValid || formRef.hasError || !formRef.changed;
  };

  const loadLocationData = (pageRequest?: IAPIGridRequest) => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Vendor.Id',
          propertyValue: AuthStore.vendorProfile.id,
        },
      ]),
    };
    vendorLocationStore?.getVMSComparison(request).subscribe(response => {
      UIStore.setPageLoader(false);
      setLocations(response.results);
    });
  };

  const loadLocationFieldData = (locationId: number) => {
    UIStore.setPageLoader(true);
    forkJoin([
      vendorLocationStore?.getVendorLocationById(locationId),
      vendorLocationStore.getLocationOperationalEssentialById(locationId),
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: [VendorLocationModel, OperationalEssentialsModel]) => {
          useUpsert.getField('vendorName').set(response[0]?.name);
          useUpsert.getField('legalBusinessName').set(response[0]?.locationLegalName);
          useUpsert.getField('managerName').set(response[1]?.managerName);
          useUpsert.getField('assitManagerName').set(response[1]?.asstManagerName);
        },
        error: error => {
          if (error.response.data.errors) {
            errorHandler(error.response.data.errors, '0');
            return;
          }
          BaseStore.showAlert(error.message, 0);
        },
      });
  };

  const loadLocationContactGridData = (locationId: number) => {
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: 500,
      filterCollection: JSON.stringify([
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: locationId,
        },
        {
          propertyName: 'Status.Id',
          propertyValue: 3,
          filterType: 'ne',
        },
        {
          propertyName: 'Contact.ContactMethod.Id',
          propertyValue: [ 1, 3 ],
          filterType: 'in',
        },
      ]),
    };
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVMSLocationContact(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: IAPIPageResponse<VendorLocationContactModel>) => {
        const results = VendorLocationContactModel.deserializeList(response.results);

        results &&
          results.forEach(({ contact, contactUsegeType }) => {
            const { contactMethod, contact: contactValue } = contact || {};

            switch (contactMethod?.id) {
              case 1:
                useUpsert.getField('opsPrimaryPhoneNo').set(contactValue);
                break;
              case 3:
                useUpsert.getField('opsPrimaryEmail').set(contactValue);
                break;
            }
          });
      });
  };

  useEffect(() => {
    onNextButtonDisable(validateForm());
  }, [ formRef.changed, formRef.isValid, formRef.hasError ]);

  useEffect(() => {
    SidebarStore.setNavLinks(newVendorOnboarding(), 'vendor', 'Vendor Locations', '/vendor/locations');
    loadInitialData();
    loadLocationData();
  }, [ activeStep ]);

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'General Information:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'coordinatingOffice',
            type: EDITOR_TYPES.DROPDOWN,
            options: locations,
            isHalfFlex: true,
            isHidden: !slideOneAndTwoStore.toggleSwitch,
          },
          {
            fieldKey: 'legalBusinessName',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isHidden: !slideOneAndTwoStore.toggleSwitch,
            isDisabled: slideOneAndTwoStore.toggleSwitch,
          },
          {
            fieldKey: 'vendorName',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isHidden: !slideOneAndTwoStore.toggleSwitch,
            isDisabled: slideOneAndTwoStore.toggleSwitch,
          },
          {
            fieldKey: 'managerName',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isDisabled: slideOneAndTwoStore.toggleSwitch,
          },
          {
            fieldKey: 'assitManagerName',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isDisabled: slideOneAndTwoStore.toggleSwitch,
          },
          {
            fieldKey: 'opsPrimaryPhoneNo',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isDisabled: slideOneAndTwoStore.toggleSwitch,
          },
          {
            fieldKey: 'opsSecondaryPhoneNo',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isDisabled: slideOneAndTwoStore.toggleSwitch,
          },
          {
            fieldKey: 'opsFaxNo',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isDisabled: slideOneAndTwoStore.toggleSwitch,
          },
          {
            fieldKey: 'opsPrimaryEmail',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isDisabled: slideOneAndTwoStore.toggleSwitch,
          },
          {
            fieldKey: 'opsSecondaryEmail',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isDisabled: slideOneAndTwoStore.toggleSwitch,
          },
        ],
      },
    ];
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const upsertSlideEight = () => {
    UIStore.setPageLoader(true);
    const request = new VendorOnBoardSlideEightModel({
      ...useUpsert.form.values(),
      tempLocationId: slideOneAndTwoStore.tempLocationId,
    });
    slideEightStore
      .upsertSlideEight(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: VendorOnBoardSlideEightModel) => {
          useUpsert.form.reset();
          useUpsert.setFormValues(VendorOnBoardSlideEightModel.deserialize(response));
        },
        error: error => {
          if (error?.response?.data?.errors) {
            errorHandler(error?.response?.data?.errors, request.id.toString());
            return;
          }
          useUpsert.showAlert(error?.message, request.id.toString());
        },
      });
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'coordinatingOffice':
        if (value) {
          loadLocationFieldData(value?.id);
          loadLocationContactGridData(value?.id);
        }
        break;
      default:
        break;
    }
    gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'coordinatingOffice':
        const filteredLocations = vendorLocationStore.vendorLocationList.filter(data => {
          return (
            data.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
            data.airportReference?.displayCode?.toLowerCase().includes(searchValue.toLowerCase()) ||
            data.vendorLocationCityReference?.cityReference?.name?.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        setLocations(filteredLocations);
        break;
      default:
        break;
    }
    return;
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      default:
        break;
    }
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    slideEightStore
      .getByVendorIdSlideEight(AuthStore.vendorProfile?.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: VendorOnBoardSlideEightModel[]) => {
        useUpsert.form.reset();
        const data = VendorOnBoardSlideEightModel.deserialize(response[0]);
        useUpsert.setFormValues(data);
        if (data.coordinatingOffice?.vendorLocationId !== 0 && slideOneAndTwoStore.toggleSwitch) {
          loadLocationFieldData(data.coordinatingOffice.vendorLocationId);
          loadLocationContactGridData(data.coordinatingOffice.vendorLocationId);
        }
      });
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    slideOneAndTwoStore.toggleSwitch = Boolean(event.target.checked);
    useUpsert.form.reset();
    loadInitialData();
    if (slideOneAndTwoStore.toggleSwitch) {
      useUpsert.getField('opsPrimaryPhoneNo').set('rules', '');
      useUpsert.getField('opsPrimaryPhoneNo').set('label', 'Operations Phone number');
      useUpsert.getField('opsPrimaryEmail').set('rules', '');
      useUpsert.getField('opsPrimaryEmail').set('label', 'Operations Primary Email');
    } else {
      useUpsert
        .getField('opsPrimaryPhoneNo')
        .set('rules', 'required|between:7,20|regex:/^\\+?[0-9]{1,3}([-.\\s]?\\d{1,4}){1,5}$/');
      useUpsert.getField('opsPrimaryPhoneNo').set('label', 'Operations Phone number*');
      useUpsert.getField('opsPrimaryEmail').set('rules', `required|regex:${regex.email}`);
      useUpsert.getField('opsPrimaryEmail').set('label', 'Operations Primary Email*');
    }
  };

  return (
    <ConfirmNavigate isBlocker={false}>
      <Typography className={classes.heading}>All communications for Handling is to be directed to:</Typography>
      <div className={classes.editorWrapperContainer}>
        <div className={classes.toggle}>
          <Typography>Self</Typography>
          <Switch
            checked={slideOneAndTwoStore.toggleSwitch}
            onChange={handleSwitchChange}
            color="primary"
            name="switch"
          />
          <Typography>Other</Typography>
        </div>
        <ViewInputControls
          isEditable={true}
          groupInputControls={groupInputControls()}
          onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
          onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
          field={fieldKey => useUpsert.getField(fieldKey)}
          onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
          onFocus={fieldKey => onFocus(fieldKey)}
        />
      </div>
    </ConfirmNavigate>
  );
};

export default inject(
  'vendorLocationStore',
  'slideOneAndTwoStore',
  'slideEightStore'
)(withStyles(styles)(observer(Slide7)));
