import React, { FC, useEffect, useState } from 'react';
import { Box, FormHelperText, OutlinedInput, TextField, Typography, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { styles } from '../../CustomNotes.styles';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { IAPIGridRequest, IAPISearchFiltersDictionary, IClasses, UIStore } from '@wings-shared/core';
import { ConfirmNavigate, RootDataStore } from '@uplink-shared/layout';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseStore, SettingsStore, VendorLocationStore, AoeDesignationStore } from '../../../../../../Stores';
import { AccordionRadioGroups, CustomAccordion, AoeDesignationModel } from '../../../../../Shared';
import { Autocomplete } from '@material-ui/lab';
import { useBaseUpsertComponent } from '@uplink/shared';

interface Props {
  classes?: IClasses;
  settingsStore: SettingsStore;
  vendorLocationStore: VendorLocationStore;
  aoeDesignationStore: AoeDesignationStore;
  onNextButtonDisable?: (boolean) => void;
  registerSaveData: (saveData: () => void) => void;
  searchFilters: IAPISearchFiltersDictionary;
}

const AOEDesignation: FC<Props> = ({
  registerSaveData,
  classes,
  settingsStore,
  vendorLocationStore,
  aoeDesignationStore,
  onNextButtonDisable,
  searchFilters,
}) => {
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();

  const [ error, setError ] = useState(false);
  const [ coordinateClearanceError, setCoordinateClearanceError ] = useState('');
  const [ generalClearanceError, setGeneralClearanceError ] = useState('');
  const [ maxPOBAltClearanceOption, setMaxPOBAltClearanceOption ] = useState(
    AoeDesignationModel.getMaxPOBAltClearanceOption()
  );
  const [ customsForPassengersOption, setCustomsForPassengersOption ] = useState(
    AoeDesignationModel.getCustomsPassengersOption()
  );

  const useUpsert = useBaseUpsertComponent<AoeDesignationModel>({}, [], searchFilters);
  const formRef = useUpsert.form;

  const saveData = () => {
    upsertAoeDesignation();
  };

  useEffect(() => {
    aoeDesignationStore.isValueChanged = false;
    loadInitialData();
    vendorLocationStore.getVmsIcaoCode().subscribe();
  }, []);

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  useEffect(() => {
    onNextButtonDisable(validateForm() || UIStore.pageLoading);
  }, [ aoeDesignationStore.isAoeDesignationValueChange ]);

  const validateForm = () => {
    if (aoeDesignationStore.isAoeDesignationValueChange) {
      return false;
    }

    return true;
  };

  const setFormValues = (data: AoeDesignationModel) => {
    aoeDesignationStore.aoeDesignationData = data;
    aoeDesignationStore.airportEntryData = data.isAirsidePickupArrangementPossible ? 1 : 2;
    aoeDesignationStore.customsAvailableData = data.isCustomsAvailableAtAirport ? 1 : 2;
    aoeDesignationStore.customsOfficersDutyValue = data.customOfficerDispatchedFromAirport;
    aoeDesignationStore.customsForPassengerData = data.appliedCustomsLocationInformations;
    aoeDesignationStore.clearanceLocationSpecificsData = data.clearanceLocationSpecifics;
    aoeDesignationStore.generalAviationClearance = data.gaClearanceAvailable ? 1 : 2;
    aoeDesignationStore.generalAviationClearanceNumber = data.maximumPersonsOnBoardAllowedForGAClearanceNotes;
    if (data?.appliedMaxPOBAltClearanceOptions.length > 0) {
      aoeDesignationStore.appliedMaxPOBAltClearanceOptions = data?.appliedMaxPOBAltClearanceOptions[0];
    }
  };
  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'AirportReference.Id',
          propertyValue: RootDataStore.locationData.airportReference?.id,
        },
      ]),
    };
    aoeDesignationStore
      .getAoeDesignation(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: AoeDesignationModel[]) => {
        const data = response.results && response.results[0];
        if (data) {
          setFormValues(data)
        }
      });
  };

  const handleErrorResponse = error => {
    if (error?.response?.data?.errors) {
      errorHandler(error?.response?.data?.errors);
      return;
    }
    if (error?.message) {
      BaseStore.showAlert(error?.message, 0);
    }
  };

  const errorHandler = (errors: object): void => {
    Object.values(errors)?.forEach(errorMessage => BaseStore.showAlert(errorMessage[0], 0));
  };

  const upsertAoeDesignation = (): void => {
    const request = new AoeDesignationModel({
      ...aoeDesignationStore.aoeDesignationData,
      isAirsidePickupArrangementPossible: aoeDesignationStore.airportEntryData === 1,
      isCustomsAvailableAtAirport: aoeDesignationStore.customsAvailableData === 1,
      gaClearanceAvailable: aoeDesignationStore.generalAviationClearance === 1,
      customOfficerDispatchedFromAirport: aoeDesignationStore.customsOfficersDutyValue,
      clearanceLocationSpecifics: aoeDesignationStore.clearanceLocationSpecificsData,
      maximumPersonsOnBoardAllowedForGAClearanceNotes: aoeDesignationStore.generalAviationClearanceNumber,
      appliedCustomsLocationInformations: aoeDesignationStore.customsForPassengerData,
      appliedMaxPOBAltClearanceOptions: [ aoeDesignationStore.appliedMaxPOBAltClearanceOptions ],
    });
    UIStore.setPageLoader(true);
    aoeDesignationStore
      ?.upsertAoeDesignation(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          aoeDesignationStore.isAoeDesignationValueChange = false;
        })
      )
      .subscribe({
        next: (response: AoeDesignationModel) => {
          loadInitialData();
        },
        error: error => {
          handleErrorResponse(error);
        },
      });
  };

  const numbersError = (value, field) => {
    let errorMessage = '';
    const validFloat = /^([0-9]{1,5})(\.[0-9]{1,2})?$/;

    if (field === 'generalAviationClearanceNumber') {
      if (parseFloat(value) > 99999.99) {
        errorMessage = 'This value is required';
        onNextButtonDisable(true);
      } else if (!/^\d*(\.\d*)?$/.test(value)) {
        errorMessage = 'The value must be a number';
        onNextButtonDisable(true);
      } else if (parseFloat(value) < 0 || parseFloat(value) > 99999.99) {
        errorMessage = 'The value must be between 0 and 99999.99';
        onNextButtonDisable(true);
      } else if (!validFloat.test(value)) {
        errorMessage = 'This value format is invalid';
        onNextButtonDisable(true);
      } else {
        onNextButtonDisable(false);
      }
      setGeneralClearanceError(errorMessage);
    } else if (field === 'clearanceLocationSpecificsData') {
      if (value.length > 200) {
        errorMessage = 'The input cannot exceed 200 characters';
        onNextButtonDisable(true);
      } else {
        onNextButtonDisable(false);
      }
      setCoordinateClearanceError(errorMessage);
    }
    return value;
  };

  const handleInputValue = (event, fieldName) => {
    const value = event.target.value;
    numbersError(value, fieldName);
    aoeDesignationStore[fieldName] = value;
    aoeDesignationStore.isAoeDesignationValueChange = true;
  };

  const isAirportEnteryData = [
    { id: 1, value: 'yes', label: 'Yes' },
    { id: 2, value: 'no', label: 'No' },
  ];

  const isCustomAvailableData = [
    { id: 1, value: 'yes', label: 'Yes' },
    { id: 2, value: 'no', label: 'No' },
  ];

  const generalAviationClearanceData = [
    { id: 1, value: 'yes', label: 'Yes' },
    { id: 2, value: 'no', label: 'No' },
  ];

  return (
    <div className={classes.mainAOEDesignationWrapper}>
      <ConfirmNavigate isBlocker={aoeDesignationStore.isAoeDesignationValueChange}>
        <>
          <Box>
            <CustomAccordion
              panelName="airportEntry"
              panelHeading="Airport Entry"
              panelBodyHeading="Is it possible to arrange airside pickup?"
              panelContent={
                <Box className={classes.pickupAddress}>
                  <AccordionRadioGroups
                    radioValue={aoeDesignationStore.airportEntryData}
                    onRadioChange={value => {
                      aoeDesignationStore.airportEntryData = value;
                      aoeDesignationStore.disableSaveButton = false;
                      aoeDesignationStore.isAoeDesignationValueChange = true;
                    }}
                    radioGroupData={isAirportEnteryData}
                  />
                </Box>
              }
            />
          </Box>
          {aoeDesignationStore.airportEntryData === 1 && (
            <>
              <Box mt={2}>
                <CustomAccordion
                  panelName="customsAvailable"
                  panelHeading="Customs Available"
                  panelBodyHeading="Is Customs available at Airport?"
                  panelContent={
                    <Box className={classes.pickupAddress}>
                      <AccordionRadioGroups
                        radioValue={aoeDesignationStore.customsAvailableData}
                        onRadioChange={value => {
                          aoeDesignationStore.customsAvailableData = value;
                          aoeDesignationStore.disableSaveButton = false;
                          aoeDesignationStore.isAoeDesignationValueChange = true;
                        }}
                        radioGroupData={isCustomAvailableData}
                      />
                    </Box>
                  }
                />
              </Box>
              <Box mt={2}>
                <CustomAccordion
                  panelName="customsOfficersDuty"
                  panelHeading="Customs Officers Duty"
                  panelBodyHeading="If Customs Officers are dispatched from another airport, 
                  which airport do they come from?"
                  panelContent={
                    <Box className={classes.pickupAddress}>
                      <Autocomplete
                        autoHighlight
                        value={aoeDesignationStore.customsOfficersDutyValue}
                        options={vendorLocationStore.airportList}
                        getOptionSelected={(option, value) => option.airportId === value.airportId}
                        getOptionLabel={option => option.label}
                        renderInput={params => <TextField {...params} placeholder="Select From List" />}
                        onChange={(event: any, newValue) => {
                          aoeDesignationStore.customsOfficersDutyValue = newValue;
                          aoeDesignationStore.isAoeDesignationValueChange = true;
                        }}
                        className={classes.inputDropdownFullWidth}
                      />
                    </Box>
                  }
                />
              </Box>
              <Box mt={2}>
                <CustomAccordion
                  panelName="customsForPassenger"
                  panelHeading="Customs for Passenger"
                  panelBodyHeading="Where on the airport can the passengers clear customs?"
                  panelContent={
                    <Box className={classes.pickupAddress}>
                      <Autocomplete
                        autoHighlight
                        getOptionSelected={(option, value) => 
                          option.customsLocationInformation?.id === value.customsLocationInformation?.id
                        }
                        value={aoeDesignationStore.customsForPassengerData}
                        options={aoeDesignationStore.getAOESettingOptions(
                          customsForPassengersOption,
                          'customsLocationInformation'
                        )}
                        renderInput={params => (
                          <TextField {...params} placeholder="Search Customs Location Information" />
                        )}
                        getOptionLabel={option => option.label}
                        onChange={(event: any, newValue) => {
                          aoeDesignationStore.customsForPassengerData = newValue;
                          aoeDesignationStore.isAoeDesignationValueChange = true;
                        }}
                        multiple={true}
                        className={classes.inputDropdownFullWidth}
                      />
                    </Box>
                  }
                />
              </Box>
              <Box mt={2}>
                <CustomAccordion
                  panelName="customsClearance"
                  panelHeading="Customs Clearance"
                  panelBodyHeading="Any Specific details to say about the clearance location?"
                  panelContent={
                    <Box className={classes.pickupAddress}>
                      <OutlinedInput
                        className={`${classes.inputDropdownFullWidth} inputTextField`}
                        id="clearance-location"
                        value={aoeDesignationStore.clearanceLocationSpecificsData}
                        onChange={e => handleInputValue(e, 'clearanceLocationSpecificsData')}
                        aria-describedby="clearance-location-text"
                        inputProps={{
                          'aria-label': 'clearance location',
                        }}
                        labelWidth={0}
                        placeholder="Enter here"
                        style={{
                          borderColor: error ? 'red' : 'initial',
                          borderWidth: '1px',
                          borderStyle: error ? 'solid' : 'none',
                        }}
                      />
                      {coordinateClearanceError && (
                        <FormHelperText style={{ color: 'red' }}>{coordinateClearanceError}</FormHelperText>
                      )}
                    </Box>
                  }
                />
              </Box>
              <Box mt={2}>
                <CustomAccordion
                  panelName="generalAviationClearance"
                  panelHeading="General Aviation Clearance"
                  panelBodyHeading="Is clearance available for General Aviation?"
                  panelContent={
                    <Box className={classes.pickupAddress}>
                      <AccordionRadioGroups
                        radioValue={aoeDesignationStore.generalAviationClearance}
                        onRadioChange={value => {
                          aoeDesignationStore.generalAviationClearance = value;
                          aoeDesignationStore.disableSaveButton = false;
                        }}
                        radioGroupData={generalAviationClearanceData}
                      />
                      <Typography className={classes.heading}>
                        Maximum number of persons on board allowed for GA Clearance
                      </Typography>
                      <OutlinedInput
                        className={`${classes.inputDropdownFullWidth} inputTextField`}
                        id="clearance-maximum-persons"
                        value={aoeDesignationStore.generalAviationClearanceNumber}
                        onChange={e => handleInputValue(e, 'generalAviationClearanceNumber')}
                        aria-describedby="clearance-location-text"
                        max="99999.99"
                        inputProps={{
                          'aria-label': 'clearance location',
                        }}
                        style={{
                          borderColor: error ? 'red' : 'initial',
                          borderWidth: '1px',
                          borderStyle: error ? 'solid' : 'none',
                        }}
                        labelWidth={0}
                        placeholder="Enter here"
                      />
                      {generalClearanceError && (
                        <FormHelperText style={{ color: 'red' }}>{generalClearanceError}</FormHelperText>
                      )}
                      <Typography className={classes.heading}>
                        Is there somewhere else the passengers must be cleared if they reach or exceed the limit?
                      </Typography>
                      <Autocomplete
                        autoHighlight
                        getOptionSelected={(option, value) => option.maxPOBOption?.id === value.maxPOBOption?.id}
                        value={aoeDesignationStore.appliedMaxPOBAltClearanceOptions}
                        options={aoeDesignationStore.getAOESettingOptions(maxPOBAltClearanceOption, 'maxPOBOption')}
                        renderInput={params => <TextField {...params} placeholder="Select Currency" />}
                        getOptionLabel={option => option.label}
                        className={classes.inputDropdownFullWidth}
                        onChange={(event: any, newValue) => {
                          aoeDesignationStore.appliedMaxPOBAltClearanceOptions = newValue;
                          aoeDesignationStore.isAoeDesignationValueChange = true;
                        }}
                      />
                    </Box>
                  }
                />
              </Box>
            </>
          )}
        </>
      </ConfirmNavigate>
    </div>
  );
};

export default inject(
  'settingsStore',
  'vendorLocationStore',
  'aoeDesignationStore'
)(withStyles(styles)(observer(AOEDesignation)));
