import {
  IAPIGridRequest,
  IAPISearchFiltersDictionary,
  IClasses,
  IOptionValue,
  ISelectOption,
  UIStore,
  Utilities,
} from '@wings-shared/core';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router';
import { inject, observer } from 'mobx-react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { styles } from './Parking.styles';
import { NO_SQL_COLLECTIONS, useBaseUpsertComponent } from '@uplink/shared';
import { AuthStore } from '@uplink-shared/security';
import { useGridState } from '@wings-shared/custom-ag-grid';

import { useUnsubscribe } from '@wings-shared/hooks';
import { AutoCompleteControl, EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { DetailsEditorWrapper, ConfirmNavigate, SidebarStore, RootDataStore } from '@uplink-shared/layout';
import { BaseStore, ParkingSeasonalityStore, SettingsStore, VendorLocationStore } from '../../../../Stores';
import { ParkingSeasonalityModel } from '../../../Shared/Models/ParkingSeasonality.model';
import { fields } from './Fields';
import {
  Airports,
  COLLECTION_NAMES,
  SettingBaseModel,
  SidebarMenuOperationalInfo,
  VendorModuleSecurity,
  ViewInputControls,
} from '../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import InfoIcon from '@material-ui/icons/Info';
import { AlertStore } from '@uvgo-shared/alert';

interface Props {
  classes?: IClasses;
  settingsStore?: SettingsStore;
  vendorLocationStore: VendorLocationStore;
  parkingSeasonalityStore: ParkingSeasonalityStore;
  searchFilters?: IAPISearchFiltersDictionary;
  navigate?: NavigateFunction;
}

const Parking: FC<Props> = ({
  classes,
  settingsStore,
  vendorLocationStore,
  parkingSeasonalityStore,
  searchFilters,
}) => {
  const [ formChgange, setFormChange ] = useState(false);
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();
  const useUpsert = useBaseUpsertComponent<ParkingSeasonalityModel>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const [ mins, setMins ] = useState(0);
  const [ hrs, setHrs ] = useState(0);
  const [ days, setDays ] = useState(0);
  const [ weeks, setWeeks ] = useState(0);
  const [ airportAlternate, setAirportAlternate ] = useState<any>([]);

  useEffect(() => {
    SidebarStore.setNavLinks(SidebarMenuOperationalInfo(), 'vendor');
    settingsStore.getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.OVERNIGHT_PARKING).subscribe();
    vendorLocationStore.getVmsIcaoCode().subscribe();
    loadInitialData();
  }, [ AuthStore.vendorProfile ]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormChange(true);
    parkingSeasonalityStore.radioValue = Number(Number((event.target as HTMLInputElement).value));
    parkingSeasonalityStore.radioValue == 2 ? (setMins(0), setHrs(0), setDays(0), setWeeks(0)) : <></>;
  };
  const totalMins = () => {
    return weeks * 10080 + days * 1440 + hrs * 60 + mins;
  };

  const convertMinutes = totalMinutes => {
    const weeks = Math.floor(totalMinutes / 10080);
    totalMinutes %= 10080;

    const days = Math.floor(totalMinutes / 1440);
    totalMinutes %= 1440;

    const hrs = Math.floor(totalMinutes / 60);
    const mins = totalMinutes % 60;
    setWeeks(weeks);
    setDays(days);
    setHrs(hrs);
    setMins(mins);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormChange(true);
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, '');
    const { target } = event;
    switch (target.id) {
      case 'mins':
        inputValue?setMins(+inputValue):setMins(0);
        break;
      case 'hrs':
        inputValue?setHrs(+inputValue):setHrs(0);
        break;
      case 'days':
        inputValue?setDays(+inputValue):setDays(0);
        break;
      case 'weeks':
        inputValue?setWeeks(+inputValue):setWeeks(0);
        break;
    }
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'General Information:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'appliedParkingSeasonDifficulty',
            type: EDITOR_TYPES.DROPDOWN,
            options: parkingSeasonalityStore.getParkingSeasonalitySettingOptions<SettingBaseModel>(
              ParkingSeasonalityModel.getMonthsList(),
              'overnightParkingSeasonDifficulty'
            ),
            multiple: true,
            isFullFlex: true,
            customLabel: field => {
              return (
                <div className={classes.label}>
                  <span>Select Month with Seasonal Parking Restrictions</span>
                </div>
              );
            },
          },
          {
            fieldKey: 'overnightParkingDifficulty',
            type: EDITOR_TYPES.DROPDOWN,
            options: settingsStore.overnightParking,
            isFullFlex: true,
            customLabel: field => {
              return (
                <div className={classes.label}>
                  <span>Any Issues With Overnight Parking</span>
                </div>
              );
            },
          },
        ],
      },
    ];
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      default:
        break;
    }
    gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      default:
        break;
    }
    return;
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      default:
        break;
    }
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    setFormChange(false);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'AirportReference.Id',
          propertyValue: RootDataStore.locationData.airportReferenceId,
        },
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: RootDataStore.locationData.locationId,
          operator: 'and',
        },
      ]),
    };
    parkingSeasonalityStore
      ?.getParkingSeasonality(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: ParkingSeasonalityModel[]) => {
        const data = ParkingSeasonalityModel.deserialize(response.results[0]);
        setAirportAlternate(data.appliedParkingAlternates);
        parkingSeasonalityStore.ParkingAlternates = data.appliedParkingAlternates;
        useUpsert.setFormValues(data);
        convertMinutes(data.maximumStayDuration);
        parkingSeasonalityStore.radioValue = data.isMaximumStayAllowed ? 1 : 2;
      });
  };


  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          Airport Parking
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="outlined"
              disabled={!formRef.changed}
              // onClick={() => getConfirmation()}
              size="large"
              hidden={!VendorModuleSecurity.isEditable}
            >
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                parkingSeasonalityStore.radioValue == 1
                  ? totalMins()>0?upsertParkingSeasonality()
                    :AlertStore.info('Maximum allowable parking duration at airport cannot be zero')
                  :parkingSeasonalityStore.radioValue == 1?upsertParkingSeasonality():<></>;
              }}
              size="large"
              disabled={(!formRef.changed || !formRef.isValid || formRef.hasError) && !formChgange}
              hidden={!VendorModuleSecurity.isEditable}
            >
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  };
  const errorHandler = (errors: object): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], '0'));
  };

  const handleErrorResponse = error => {
    if (error.response?.data?.errors) {
      errorHandler(error.response?.data?.errors);
      return;
    }
    if (error?.message) {
      useUpsert.showAlert(error?.message, '0');
    }
  };
  const handleDropDownChange = (event: any): void => {
    setFormChange(true);
    setAirportAlternate(event);
  };
  const upsertParkingSeasonality = (): void => {
    UIStore.setPageLoader(true);
    const request = new ParkingSeasonalityModel({
      ...useUpsert.form.values(),
      isMaximumStayAllowed: parkingSeasonalityStore.radioValue === 1,
      maximumStayDuration: totalMins(),
      appliedParkingAlternates: airportAlternate,
    });
    parkingSeasonalityStore
      ?.upsertParkingSeasonality(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: (response: ParkingSeasonalityModel) => {
          useUpsert.form.reset();
          setFormChange(false);
          parkingSeasonalityStore.ParkingAlternates = response.appliedParkingAlternates;
          setAirportAlternate(response.appliedParkingAlternates);
          useUpsert.setFormValues(response);
          convertMinutes(response.maximumStayDuration);
          parkingSeasonalityStore.radioValue = response.isMaximumStayAllowed ? 1 : 2;
        },
        error: error => {
          handleErrorResponse(error);
        },
      });
  };

  return (
    <Box>
      <ConfirmNavigate isBlocker={formRef.changed}>
        <DetailsEditorWrapper
          headerActions={headerActions()}
          isEditMode={VendorModuleSecurity.isEditable}
          classes={{ headerActions: classes.headerActions }}
        >
          <div className={classes.editorWrapperContainer}>
            <ViewInputControls
              isEditable={VendorModuleSecurity.isEditable}
              groupInputControls={groupInputControls()}
              onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
              onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
              field={fieldKey => useUpsert.getField(fieldKey)}
              onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
              onFocus={fieldKey => onFocus(fieldKey)}
              classes={{ groupInputControls: classes.groupInputControls }}
            />
            <FormControl>
              <div className={classes.maximumStay}>
                Is there a maximum allowable parking duration (max ground time) at your airport?
              </div>
              <RadioGroup
                className={classes.radio}
                value={parkingSeasonalityStore.radioValue}
                onChange={handleRadioChange}
              >
                <FormControlLabel value={1} control={<Radio />} label="Yes" />
                <FormControlLabel value={2} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            {parkingSeasonalityStore.radioValue === 1 && (
              <div className={classes.textDateTime}>
                <TextField
                  label="MINUTE(S)"
                  id="mins"
                  value={mins}
                  onChange={handleChange}
                />
                <TextField
                  label="HOUR(S)"
                  id="hrs"
                  value={hrs}
                  onChange={handleChange}
                />
                <TextField
                  label="DAY(S)"
                  id="days"
                  value={days}
                  inputProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  onChange={handleChange}
                />
                <TextField
                  label="WEEK(S)"
                  id="weeks"
                  value={weeks}
                  onChange={handleChange}
                />
              </div>
            )}
            <div className={classes.flexIcon}>
              <span>Specify Alternate Airport for Parking</span>
              <Tooltip title="Please separate ICAOs with commas" placement="right">
                <InfoIcon className={classes.imageIcon} />
              </Tooltip>
            </div>
            <AutoCompleteControl
              value={airportAlternate}
              options={parkingSeasonalityStore.getParkingSeasonalitySettingOptions<Airports>(
                vendorLocationStore.airportList,
                'airportReference'
              )}
              placeHolder="Specify Alternate Airport for Parking"
              getOptionLabel={option => option.label}
              onDropDownChange={handleDropDownChange}
              showTooltip={true}
              getChipTooltip={field => field.label}
              multiple={true}
              classes={{ chip: classes.chip, inputWrapper: classes.parkingwidthhere }}
            ></AutoCompleteControl>
          </div>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    </Box>
  );
};

export default inject(
  'settingsStore',
  'vendorLocationStore',
  'parkingSeasonalityStore'
)(withStyles(styles)(observer(Parking)));
